import React, {useEffect, useState} from "react";
import {fetchRecord} from "../../../../../actions/fetchRecord";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import {customStylesSelect} from "../../../../../constants/customStylesSelect";
import {updateRecord} from "../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../actions/storeRecord";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";

const Granulometria = ( props ) => {
    const [idUltimaMuestra,setIdUltimaMuestra] = useState(null);
    const [idTipoMaterial, setIdTipoMaterial] = useState(null);
    const [dataForm,setDataForm] = useState(null);
    const { register,handleSubmit,errors } = useForm();
    const history = useHistory();
    
    useEffect(() => {
        async function getUltimaMuestra() {
            const result = await fetchRecord(null,'muestras/ultima');
            if(result){
                setIdUltimaMuestra(result.id);
            }
        };

        getUltimaMuestra();
    },[]);

    const tiposMaterial = [
        { value:'Ninguno',label:'Ninguno',name:'material' },
        { value:'Balastre',label:'Balastre',name:'material' },
        { value:'Granito',label:'Granito',name:'material' },
        { value:'Dren',label:'Dren',name:'material' },
        { value:'Filtro',label:'Filtro',name:'material' },
    ];

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };
    
    return(
        <div>
            <p className="divider animate fadeInRight">
                Datos de la <b> Muestra { props.idRegistro ? props.idRegistro : idUltimaMuestra + 1 } </b> de tipo <b>Compresión</b>  -  Paso { props.seccion } de 3
            </p>
            <hr/>
            <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
                <Row>
                    <Col sm={ 4 }>
                        <FormGroup>
                            <Label>* Material</Label>
                            <Select styles={ customStylesSelect }
                                    options={ tiposMaterial }
                                    placeholder="Selecciona ..."
                                    value={tiposMaterial.find(op => {
                                        return op.value === idTipoMaterial
                                    })}
                                    onChange={(event) => {setIdTipoMaterial(event.value)}}
                            />
                            <input
                                tabIndex={ -1 }
                                autoComplete="off"
                                style={ {  opacity: 0, height: 0, display: 'none' } }
                                value={ idTipoMaterial }
                                name="material"
                                ref={ register({ required:true }) }
                            />
                            { errors.material && <small>Ingresa un valor</small> }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={ 8 }>
                        <FormGroup>
                            <Label>Observaciones</Label>
                            <textarea className="form-control"
                                      type="textarea"
                                      name="observaciones"
                                      defaultValue={ dataForm?.observaciones ?  dataForm?.observaciones : undefined }
                                      ref={ register }/>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>

    );
};

export default Granulometria;