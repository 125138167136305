import React  from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {url_base} from "../../../../constants/api_url";
import StyledButton from "../../button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faFileSignature} from "@fortawesome/free-solid-svg-icons";
import {Viewer} from "@react-pdf-viewer/core";

const ModalDescargaReporte = props => {

    return(<Modal isOpen={props.modalControl} toggle={() => props.toggleModalControl(!props.modalControl)} className={props.className}>
        <ModalHeader toggle={() => props.toggleModalControl()}>Descargar reporte {props.idReporte}</ModalHeader>
        <ModalBody className="text-center">
            <Viewer
                fileUrl={`${url_base}reportes/${props.idReporte}/1`}
            />
            <p>¿Desea descargar el reporte {props.idReporte} con o sin Firmas?</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
            <a href={`${url_base}reportes/${props.idReporte}/0`} download={`reporte-${props.tipoMuestra}-${props.idMuestra}.pdf`}>
                <StyledButton className="gray">
                    <FontAwesomeIcon icon={faFile}/>
                    {'   '} Sin Firmas
                </StyledButton>
            </a>
            <a href={`${url_base}reportes/${props.idReporte}/1`} download={`reporte-${props.tipoMuestra}-${props.idMuestra}.pdf`}>
                <StyledButton className="gray">
                    <FontAwesomeIcon icon={faFileSignature}/>
                    {'   '} Con Firmas
                </StyledButton>
            </a>
        </ModalFooter>
    </Modal>)};

export default ModalDescargaReporte;

