import React from "react";
import Muestras from "../index";

const url = 'muestras-tipo/Acero';
const tituloTabla = 'Muestras Acero';
const tipoMuestra = 'Acero';

const MuestrasAcero = () => (
    <Muestras
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasAcero;