import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {updateRecord} from "../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import { withRouter, useHistory } from 'react-router-dom';
import {useUsuario} from "../../../../../context/usuario-context";

const Compactacion = ( props ) => {
    const {  register, handleSubmit, errors  } = useForm();
    const { setBreadcrumbItems } = useUsuario();
    const [dataForm,setDataForm] = useState(null);
    const [pesoHumedo,setPesoHumedo] = useState(null);
    const [pesoSeco,setPesoSeco] = useState(null);
    const [pesoVolLugar,setPesoVolLugar] = useState(null);

    const [ pesoVolMaximo, setPesoVolMaximo ] = useState(0);

    const [porcentajeHumedad,setPorcentajeHumedad] = useState(0);
    const [porcentaje,setPorcentaje] = useState(0);
    const history = useHistory();

    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/ensayes/compactacion',
                'item':'Ensayes Compactación'
            },
            {
                'item':'Ensaye Compactación'
            }
        ]);

        if(props.record.carga > 0) {
            setDataForm(props.record);
            setPesoVolMaximo(props.record.peso_volumetrico_seco_max);
            setPesoVolLugar(props.record.peso_volumetrico_lugar);
            setPesoSeco(props.record.peso_seco);
            setPesoHumedo(props.record.peso_humedo);

            calculaPorcentajeCompactacion();
            calculaPorcentajeHumedad();

            props.setButtonGuardarDisabled(false);

        }
    },[]);

    useEffect(() => {

        if( pesoVolLugar && pesoVolMaximo) {
            calculaPorcentajeCompactacion();
        }

    },[pesoVolLugar,pesoVolMaximo]);

    useEffect(() => {

        if( pesoHumedo && pesoSeco) {
            calculaPorcentajeHumedad();
        }

    },[pesoHumedo,pesoSeco]);

    const getPesoVulumetrico = () => {
        console.log(dataForm);
    };

    const calculaPorcentajeCompactacion = () => {

        let porcentajeCompactacion = (pesoVolLugar / pesoVolMaximo) * 100;
        setPorcentaje(Math.round(porcentajeCompactacion));
    };

    const calculaPorcentajeHumedad = () => {

        let porcentajeHumedad = ((pesoHumedo-pesoSeco) / pesoSeco) * 100;
        setPorcentajeHumedad(porcentajeHumedad.toFixed(1));
        props.setButtonGuardarDisabled(false);

    };

    const onSubmit = async (data) => {

        data.porcentaje_compactacion = porcentaje;
        data.porcentaje_humedad = porcentajeHumedad;

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(data,'ensayes',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado el ensaye",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar el ensaye",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }


            //Redirect al formulario de edición
            history.push(`/capturista/ensayes`);
        }

    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso Volumétrico del lugar</Label>
                        <input className="form-control"
                               type="number"
                               name="peso_volumetrico_lugar"
                               onChange={(event) => {
                                   setPesoVolLugar(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.peso_volumetrico_lugar : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_volumetrico_lugar && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso volumétrico seco máximo</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_volumetrico_seco_max"
                               onChange={(event) => {
                                   setPesoVolMaximo(event.target.value);
                               }}
                               defaultValue={ dataForm?.peso_volumetrico_seco_max ? dataForm.peso_volumetrico_seco_max : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_volumetrico_seco_max && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>


            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Húmedad del lugar</Label>
                        <input className="form-control"
                               type="number"
                               step="any"

                               name="humedad_lugar"

                               defaultValue={ dataForm ? dataForm.humedad_lugar : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.humedad_lugar && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Húmedad óptima</Label>
                        <input className="form-control"
                               type="number"
                               step="any"
                               name="humedad_optima"
                               defaultValue={ dataForm ? dataForm.humedad_optima : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.humedad_optima && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso Volumétrico Húmedo</Label>
                        <input className="form-control"
                               type="number"
                               name="peso_volumetrico_humedo"
                               defaultValue={ dataForm ? dataForm.peso_volumetrico_humedo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_volumetrico_humedo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso Húmedo</Label>
                        <input className="form-control"
                               type="number"
                               name="peso_humedo"
                               onChange={(event) => {
                                   setPesoHumedo(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.peso_humedo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_humedo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso seco</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_seco"
                               onChange={(event) => {
                                   setPesoSeco(event.target.value);
                               }}
                               defaultValue={ dataForm?.peso_seco ? dataForm.peso_seco : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_seco && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        Porcentaje de Húmedad: { porcentajeHumedad } % <br/>
                        Porcentaje de Compactación: { porcentaje }%<br/>
                    </p>
                </Col>
            </Row>
        </Form>
    )
};

export default withRouter(Compactacion);