import React, {useEffect, useState} from "react";
import {Row, Col, Button } from "reactstrap";
import MUIDataTable from "mui-datatables";
import {muiTableOptions} from "../../../../../constants/muiTableOptions";
import {fetchRecords} from "../../../../../actions/fetchRecords";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import {useHistory, withRouter} from "react-router-dom";
import {useUsuario} from "../../../../../context/usuario-context";
import moment from "moment";

const EnsayesVigas = ( ) => {
    const { setFilterBar, fechaInicioFiltro, fechaFinFiltro } = useUsuario();
    const [records,setRecords] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getRecords();
    },[]);

    const getRecords = async () => {
        try {

            const result = await fetchRecords('ensayes-tipo/Vigas');

            if(result) {
                setRecords(result.done);
            }


        }catch (e) {
            console.log(e);
        }
    };

    const columns = [{
        name: "ensaye",
        label: "Ensaye",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "muestra",
        label: "Muestra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "obra",
        label: "Obra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "edad",
        label: "Edad (Días)",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "fecha_de_ensaye",
        label: "Fecha de ensaye",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "molde",
        label: "Molde",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "dias_extra",
        label: "Días extra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "porcentaje_resistencia",
        label: "% Resistencia",
        options: {
            filter: false,
            sort: true,
        }
    },{
        name: "cumple_ensaye",
        label: "Cumple",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "id",
        label: "Acciones",
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                        <Button type="Button" title="Revisar Ensaye" onClick={() => {

                            //Redirect al formulario de edición
                            history.push(`/capturista/EnsayesVigas/revisar/${tableMeta.rowData[0]}`);

                        }} className="mr-2 btnAction">
                            <FontAwesomeIcon
                                style={
                                    tableMeta.rowData[8] === 'Aceptable' || tableMeta.rowData[8] === 'Si' ? {color:'#2FBF71'} : tableMeta.rowData[8] === 'No' ? {color:'#DB5461'} : {}
                                }
                                icon={faClipboardCheck}/>
                        </Button>
                    </div>
                );
            }
        }
    }];

    return(
        <div>
            <Row className="mt-4">
                <Col>
                    <MUIDataTable
                        title={"Ensayes Vigas"}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default withRouter(EnsayesVigas);