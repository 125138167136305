import React from "react";
import {Col, Button, Row} from "reactstrap";
import { useHistory, withRouter } from 'react-router-dom';
const DetallesCompactacion = props => {
    const history = useHistory();

    return(
        <div>
            <Row>
                <Col className="text-center">
                    <h4>Datos generales de la muestra</h4>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Muestra:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.muestra.id }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Obra:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.muestra.obra_id }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Fecha de la muestra:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.muestra.fecha_de_la_muestra }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Tipo de muestra:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.muestra.tipo_muestra }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">¿Cumple?</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.muestra.cumple }</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-center">
                    <h4>Datos de la muestra de tipo {props.datosMuestra.muestra.tipo_muestra}</h4>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Compactación de:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.compactacion_de }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Báscula de:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.bascula }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Método de compactación:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.compactacion_de }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Tipo de secado:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.secado }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Peso volumen arena:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.peso_volumen_arena }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Grado de compactación:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.grado_compactacion }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Grado de compactación:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.grado_compactacion }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Tipo de compactación:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.tipo_compactacion }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Lote:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.lote }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Nivel:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.nivel }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Etapa:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.etapa }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Paquete:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.paquete }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Fracción:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.fraccion }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Ubicación:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.ubicacion }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Manzana:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.manzana }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Número de capa:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.numero_capa }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Cadenamiento Inicial:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.cadenamiento_inicial }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Cadenamiento Final:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.cadenamiento_final }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Eje:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.eje }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Unidad:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.unidad }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Notas:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.notas ? props.datosMuestra.datos_muestra.notas : 'Sin Notas' }</p>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <p className="labelDetalles">Tipo de estructura:</p>
                </Col>
                <Col className="text-left">
                    <p>{ props.datosMuestra.datos_muestra.tipo_estructura }</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="text-center">
                    <h4>Datos de los ensayes de la muestra</h4>
                </Col>
            </Row>
            { props.datosMuestra.ensayes.map((val) => {

                return(
                    <div>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Fecha de ensaye:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.fecha_de_ensaye }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Peso Volumétrico del lugar:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.peso_volumetrico_lugar }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Peso volumétrico seco máximo:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.peso_volumetrico_seco_max }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Húmedad del lugar:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.humedad_lugar }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Húmedad óptima:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.humedad_optima }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Peso Volumétrico Húmedo:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.peso_volumetrico_humedo }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Peso Húmedo:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.peso_humedo }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Peso seco:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.peso_seco }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Porcentaje de húmedad:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.porcentaje_humedad }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                <p className="labelDetalles">Porcentaje de compactación:</p>
                            </Col>
                            <Col className="text-left">
                                <p>{ val.dato_ensaye.porcentaje_compactacion }</p>
                            </Col>
                        </Row>
                        <Row className="mt-4 text-center">
                            <Col>
                                <Button className="primary btn btn-secondary" onClick={() => history.goBack()}>
                                    Regresar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }) }

        </div>
    )
};

export default withRouter(DetallesCompactacion);