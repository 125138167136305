import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [idTipoDoblado,setidTipoDoblado] = useState(undefined);
    const [idTipoNoDesignacion,setIdTipoNoDesignacion] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const tiposDoblado = [
        { value:'Pasa',label:'Pasa',name:'doblado' },
        { value:'No pasa',label:'No pasa',name:'doblado' },
    ];

    const tiposNoDesignacion = [
        { value:'2 (1/4")',label:'2 (1/4")',name:'no_designacion' },
        { value:'2.5 (5/16")',label:'2.5 (5/16")',name:'no_designacion' },
        { value:'3 (3/8")',label:'3 (3/8")',name:'no_designacion' },
        { value:'4 (1/2")',label:'4 (1/2")',name:'no_designacion' },
        { value:'5 (5/8")',label:'5 (5/8")',name:'no_designacion' },
        { value:'6 (3/4")',label:'6 (3/4")',name:'no_designacion' },
        { value:'7 (7/8")',label:'7 (7/8")',name:'no_designacion' },
        { value:'8 (1")',label:'8 (1")',name:'no_designacion' },
        { value:'9 (1 1/8")',label:'9 (1 1/8")',name:'no_designacion' },
        { value:'10 (1 1/4")',label:'10 (1 1/4")',name:'no_designacion' },
        { value:'10.64',label:'10.64',name:'no_designacion' },
        { value:'11 (1 3/8")',label:'11 (1 3/8")',name:'no_designacion' },
        { value:'12 (1 1/2")',label:'12 (1 1/2")',name:'no_designacion' },
        { value:'5/16"',label:'5/16"',name:'no_designacion' },
        { value:'1/4"',label:'1/4"',name:'no_designacion' },
        { value:'3/16"',label:'3/16"',name:'no_designacion' },
        { value:'5/32"',label:'5/32"',name:'no_designacion' },
        { value:'7/32"',label:'7/32"',name:'no_designacion' },
    ];

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
            setidTipoDoblado(props.mainObjectData[1].datos_muestra.doblado);
            setIdTipoNoDesignacion(props.mainObjectData[1].datos_muestra.no_designacion);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Colada</Label>
                        <input className="form-control" type="text" name="colada"
                               defaultValue={ dataForm ? dataForm.colada : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.colada && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Masa unitaria</Label>
                        <input className="form-control" type="text" name="masa_unitaria" 
                               defaultValue={ dataForm ? dataForm.masa_unitaria : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.masa_unitaria && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Espacio de corrugaciones</Label>
                        <input className="form-control" type="text" name="espacio_corrugaciones"
                               defaultValue={ dataForm ? dataForm.espacio_corrugaciones : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.espacio_corrugaciones && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Inclinación de corrugación</Label>
                        <input className="form-control" type="text" name="inclinacion_corruga"
                               defaultValue={ dataForm ? dataForm.inclinacion_corruga : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.inclinacion_corruga && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Esfuerzo fluencia</Label>
                        <input className="form-control" type="text" name="esfuerzo_fluencia"
                               defaultValue={ dataForm ? dataForm.esfuerzo_fluencia : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.esfuerzo_fluencia && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de doblado</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposDoblado }
                                placeholder="Selecciona ..."
                                value={tiposDoblado.find(op => {
                                    return op.value === idTipoDoblado
                                })}
                                onChange={(event) => {setidTipoDoblado(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoDoblado }
                            name="doblado"
                            ref={ register({ required:true }) }
                        />
                        { errors.doblado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Marca</Label>
                        <input className="form-control"
                               type="text"
                               name="marca"
                               defaultValue={ dataForm ? dataForm.marca : undefined }
                               ref={register({required:true})}/>
                        {errors.marca && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Secuencia</Label>
                        <input className="form-control"
                               type="text"
                               name="secuencia"
                               defaultValue={ dataForm ? dataForm.secuencia : undefined }
                               ref={register({required:true})}/>
                        {errors.secuencia && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Longitud promedio</Label>
                        <input className="form-control"
                               type="text"
                               name="longitud_promedio"
                               defaultValue={ dataForm ? dataForm.longitud_promedio : undefined }
                               ref={register({required:true})}/>
                        {errors.longitud_promedio && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Área transversal</Label>
                        <input className="form-control"
                               type="text"
                               name="area_transversal"
                               defaultValue={ dataForm ? dataForm.area_transversal : undefined }
                               ref={register({required:true})}/>
                        {errors.area_transversal && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Altura corrugaciones</Label>
                        <input className="form-control"
                               type="text"
                               name="altura_corrugaciones"
                               defaultValue={ dataForm ? dataForm.altura_corrugaciones : undefined }
                               ref={register({required:true})}/>
                        {errors.altura_corrugaciones && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Carga límite elástico</Label>
                        <input className="form-control"
                               type="text"
                               name="carga_limite_elastico"
                               defaultValue={ dataForm ? dataForm.carga_limite_elastico : undefined }
                               ref={register({required:true})}/>
                        {errors.carga_limite_elastico && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de varilla</Label>
                        <input className="form-control"
                               type="text"
                               name="tipo_varilla"
                               defaultValue={ dataForm ? dataForm.tipo_varilla : undefined }
                               ref={register({required:true})}/>
                        {errors.tipo_varilla && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Número de designación</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposNoDesignacion }
                                placeholder="Selecciona ..."
                                value={tiposNoDesignacion.find(op => {
                                    return op.value === idTipoNoDesignacion
                                })}
                                onChange={(event) => {setIdTipoNoDesignacion(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoNoDesignacion }
                            name="no_designacion"
                            ref={ register({ required:true }) }
                        />
                        { errors.no_designacion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Esfuerzo máximo</Label>
                        <input className="form-control"
                               type="text"
                               name="esfuerzo_maximo"
                               defaultValue={ dataForm ? dataForm.esfuerzo_maximo : undefined }
                               ref={register({required:true})}/>
                        {errors.esfuerzo_maximo && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion1;