import React from "react";
import Ensayes from "../index";

const url = 'ensayes-tipo/Granulometría';
const tituloTabla = 'Ensayes pendientes de Granulometría';
const tipoMuestra = 'Granulometría';

const EnsayesGranulometria = () => (
    <Ensayes
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default EnsayesGranulometria;