import {url_base} from "./../constants/api_url";
import CookieService from "../services/CookieService";
import axios from "axios";
const api_url = url_base;

export const fetchRecords = async (resource) => {

    const authToken = CookieService.get('access_token');

    const headers = {
        "Content-Type": "application/json",
    };

    if(authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    const options = {
        url:`${api_url}${resource}`,
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await axios(options);

        if(response) {
            return response.data;
        }
    }catch (error) {
        //Si hay error de autentificación se redirecciona al login
        if(error.response.status === 401) {
            window.location.href = '/';
        }
    }

};