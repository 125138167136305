import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [dataForm,setDataForm] = useState(null);

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Profundidad</Label>
                        <input className="form-control"
                               type="text"
                               name="profundidad"
                               defaultValue={ dataForm ? dataForm.profundidad : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.profundidad && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Diámetro molde</Label>
                        <input className="form-control" 
                               type="text" 
                               name="diametro_molde" 
                               defaultValue={ dataForm ? dataForm.diametro_molde : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.diametro_molde && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Martillo</Label>
                        <input className="form-control"
                               type="number" 
                               name="martillo"
                               defaultValue={ dataForm ? dataForm.martillo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.martillo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Altura caída</Label>
                        <input className="form-control" 
                               type="text" 
                               name="altura_caida"
                               defaultValue={ dataForm ? dataForm.altura_caida : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.altura_caida && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Inclinación de corrugación</Label>
                        <input className="form-control" 
                               type="text"
                               name="variante"
                               defaultValue={ dataForm ? dataForm.variante : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.variante && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de la muestra</Label>
                        <input className="form-control" 
                               type="text" 
                               name="tipo_muestra"
                               defaultValue={ dataForm ? dataForm.tipo_muestra : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.tipo_muestra && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Secado</Label>
                        <input className="form-control"
                               type="text"
                               name="secado"
                               defaultValue={ dataForm ? dataForm.secado : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.secado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Volumen molde</Label>
                        <input className="form-control"
                               type="text"
                               name="volumen_molde"
                               defaultValue={ dataForm ? dataForm.volumen_molde : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.volumen_molde && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso martillo</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_martillo"
                               defaultValue={ dataForm ? dataForm.peso_martillo : undefined }
                               ref={register({required:true})}/>
                        {errors.peso_martillo && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Número de golpes de capa</Label>
                        <input className="form-control"
                               type="text"
                               name="numero_golpes_capa"
                               defaultValue={ dataForm ? dataForm.numero_golpes_capa : undefined }
                               ref={register({required:true})}/>
                        {errors.numero_golpes_capa && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Energía de compactación</Label>
                        <input className="form-control"
                               type="text"
                               name="energia_compactacion"
                               defaultValue={ dataForm ? dataForm.energia_compactacion : undefined }
                               ref={register({required:true})}/>
                        {errors.energia_compactacion && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Banco</Label>
                        <input className="form-control"
                               type="text"
                               name="banco"
                               defaultValue={ dataForm ? dataForm.banco : undefined }
                               ref={register({required:true})}/>
                        {errors.banco && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Molde</Label>
                        <input className="form-control"
                               type="number"
                               name="molde"
                               defaultValue={ dataForm ? dataForm.molde : undefined }
                               ref={register({required:true})}/>
                        {errors.molde && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso del molde</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_molde"
                               defaultValue={ dataForm ? dataForm.peso_molde : undefined }
                               ref={register({required:true})}/>
                        {errors.peso_molde && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Diámetro del martillo</Label>
                        <input className="form-control"
                               type="text"
                               name="diametro_martillo"
                               defaultValue={ dataForm ? dataForm.diametro_martillo : undefined }
                               ref={register({required:true})}/>
                        {errors.diametro_martillo && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Número de capas</Label>
                        <input className="form-control"
                               type="text"
                               name="numero_capas"
                               defaultValue={ dataForm ? dataForm.numero_capas : undefined }
                               ref={register({required:true})}/>
                        {errors.numero_capas && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion1;