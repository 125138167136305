import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [dataForm,setDataForm] = useState(null);
    const [ fechaFabricacion, setFechaFabricacion ] = useState(new Date());

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        data.fecha_fabricacion = moment(fechaFabricacion).format('YYYY-MM-DD');
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Cantidad de piezas</Label>
                        <input className="form-control"
                               type="text"
                               name="cantidad_piezas"
                               defaultValue={ dataForm ? dataForm.cantidad_piezas : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.cantidad_piezas && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Fecha de elaboración</Label>
                        <br/>
                        <DatePicker
                            locale="es"
                            className="form-control"
                            name="fecha"
                            dateFormat="dd/MMMM/yyyy"
                            selected={ fechaFabricacion }
                            onChange={ date => setFechaFabricacion(date) } />
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Resistencia del proyecto</Label>
                        <input className="form-control"
                               type="text"
                               name="resistencia"
                               defaultValue={ dataForm ? dataForm.resistencia : undefined }
                               ref={register({required:true})}/>
                        {errors.resistencia && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Cantidad de ensayes</Label>
                        <input className="form-control"
                               type="text"
                               name="cantidad_muestras"
                               defaultValue={ dataForm ? dataForm.cantidad_muestras : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.cantidad_muestras && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de prefabricado</Label>
                        <input className="form-control"
                               type="text"
                               name="tipo_prefabricado"
                               defaultValue={ dataForm ? dataForm.tipo_prefabricado : undefined }
                               ref={register({required:true})}/>
                        {errors.tipo_prefabricado && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Marca de prefabricado</Label>
                        <input className="form-control"
                               type="text"
                               name="marca_prefabricado"
                               defaultValue={ dataForm ? dataForm.marca_prefabricado : undefined }
                               ref={register({required:true})}/>
                        {errors.marca_prefabricado && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Proveedor</Label>
                        <input className="form-control"
                               type="text"
                               name="proveedor"
                               defaultValue={ dataForm ? dataForm.proveedor : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>No. de remisión</Label>
                        <input className="form-control"
                               type="text"
                               name="remision"
                               defaultValue={ dataForm ? dataForm.remision : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion1;