import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {updateRecord} from "../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import { withRouter, useHistory } from 'react-router-dom';
import {useUsuario} from "../../../../../context/usuario-context";

const Blocks = ( props ) => {
    const {  register, handleSubmit, errors  } = useForm();
    const { setBreadcrumbItems } = useUsuario();
    const [dataForm,setDataForm] = useState(null);
    const [pesoHumedo,setPesoHumedo] = useState(null);
    const [pesoSeco,setPesoSeco] = useState(null);


    const [largo,setLargo] = useState(null);
    const [ancho,setAncho] = useState(null);

    const [area,setArea] = useState(0);

    const [resistencia1,setResistencia1] = useState(0);
    const [resistencia2,setResistencia2] = useState(0);


    const [cargaN,setCargaN] = useState(null);

    const [porcentajeAbsorcion,setporcentajeAbsorcion] = useState(0);
    const [porcentajeResistencia,setPorcentajeResistencia] = useState(0);
    const history = useHistory();

    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/ensayes/block',
                'item':'Ensayes Blocks'
            },
            {
                'item':'Datos Ensaye'
            }
        ]);

        console.log(props.record.porcentaje_absorcion)
        if(props.record.carga_n > 0) {
            setDataForm(props.record);
            setArea(props.record.area);
            setResistencia1(props.record.resistencia_1);
            setResistencia2(props.record.resistencia_2);
            setCargaN(props.record.carga_n);
            setporcentajeAbsorcion(props.record.porcentaje_absorcion);
            setPorcentajeResistencia(props.record.porcentaje_resistencia);

            props.setButtonGuardarDisabled(false);

        }
    },[]);

    useEffect(() => {

        if( largo && ancho) {
            calculaArea();
        }

    },[largo,ancho]);

    useEffect(() => {

        if( pesoHumedo && pesoSeco) {
            calculaporcentajeAbsorcion();
        }

    },[pesoHumedo,pesoSeco]);

    useEffect(() => {

        if( resistencia1 ) {
            setResistencia2((resistencia1 / 10.197).toFixed(1));
            setPorcentajeResistencia(((resistencia1*100) / props.resistenciaProyecto).toFixed(1));
        }

    },[resistencia1]);

    const calculaArea = () => {
        setArea(largo*ancho);
    }


    const calculaporcentajeAbsorcion = () => {

        let porcentajeAbsorcion = ((pesoHumedo-pesoSeco) / pesoSeco) * 100;
        setporcentajeAbsorcion(porcentajeAbsorcion.toFixed(1));
        props.setButtonGuardarDisabled(false);
    };

    const onSubmit = async (data) => {

        data.resistencia_1 = resistencia1;
        data.resistencia_2 = resistencia2;
        data.area = area;
        data.porcentaje_resistencia = porcentajeResistencia;
        data.porcentaje_absorcion = porcentajeAbsorcion;
        data.carga_n = cargaN;

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(data,'ensayes',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado el ensaye",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar el ensaye",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }


            //Redirect al listado
            history.push(`/capturista/ensayes/Blocks`);
        }

    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Largo</Label>
                        <input className="form-control"
                               type="number"
                               name="largo"
                               onChange={(event) => {
                                   setLargo(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.largo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.largo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Ancho</Label>
                        <input className="form-control"
                               type="text"
                               name="ancho"
                               onChange={(event) => {
                                   setAncho(event.target.value);
                               }}
                               defaultValue={ dataForm?.ancho ? dataForm.ancho : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.ancho && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Alto</Label>
                        <input className="form-control"
                               type="text"
                               name="alto"
                               defaultValue={ dataForm?.alto ? dataForm.alto : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.alto && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso Húmedo</Label>
                        <input className="form-control"
                               type="number"
                               step="any"
                               name="peso_humedo"
                               onChange={(event) => setPesoHumedo(event.target.value)}
                               defaultValue={ dataForm ? dataForm.peso_humedo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_humedo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso Seco</Label>
                        <input className="form-control"
                               type="number"
                               step="any"
                               name="peso_seco"
                               onChange={(event) => setPesoSeco(event.target.value)}
                               defaultValue={ dataForm ? dataForm.peso_seco : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_seco && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Carga (Kgf)</Label>
                        <input className="form-control"
                               type="number"
                               name="carga_kgf"
                               onChange={(event) => {
                                   setCargaN((event.target.value * 9.8).toFixed(1));
                                   setResistencia1((event.target.value / area).toFixed(1));
                               }}
                               defaultValue={ dataForm ? dataForm.carga_kgf : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.carga_kgf && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        Área (cm²): { area } <br/>
                        Carga (N): { cargaN } <br/>
                        Resistencia (Kgf/cm²): { resistencia1 } <br/>
                        Resistencia (mPa): { resistencia2 } <br/>
                        Resistencia (%): { porcentajeResistencia }%<br/>
                        Absorción (%): { porcentajeAbsorcion } % <br/>
                    </p>
                </Col>
            </Row>
        </Form>
    )
};

export default withRouter(Blocks);