import React from "react";
import Muestras from "../index";

const url = 'muestras-tipo/Granulometría';
const tituloTabla = 'Muestras Granulometría';
const tipoMuestra = 'Granulometría';

const MuestrasGranulometria = () => (
    <Muestras
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasGranulometria;