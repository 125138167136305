import React, {useEffect, useState} from "react";
import { Col, Row, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Compresion from "./compresion";
import 'moment/locale/es';
import {useHistory, withRouter} from 'react-router-dom';
import {fetchRecord} from "../../../../actions/fetchRecord";
import Compactacion from "./compactacion";
import Blocks from "./blocks";

const FormularioEnsayes = props => {
    const [ idMuestra, setIdMuestra ] = useState(null );
    const [ edadEnsaye, setEdadEnsaye ] = useState(null );
    const [ tipoEnsaye, setTipoEnsaye ] = useState(null );
    const [ formaEnsaye, setFormaEnsaye ] = useState( null );
    const [ resistenciaProyecto, setResistenciaProyecto ] = useState( null );
    const [ compactacionProyecto, setCompactacionProyecto ] = useState( null );
    const [ espesorCapa, setEspesorCapa ] = useState( null );
    const [ buttonGuardarDisabled, setButtonGuardarDisabled ] = useState(true);
    const [ record, setRecord ] = useState(null);

    const history = useHistory();
    const idRegistro  = props.match.params.idRegistro;

    useEffect(() => {
        async function getRecord() {
            try {
                const result = await fetchRecord(idRegistro,'ensayes');
                console.log(result.done);
                if(result) {
                    setIdMuestra(result.done.muestra_id);
                    setEdadEnsaye(result.done.edad);
                    setTipoEnsaye(result.done.tipo_muestra);
                    setFormaEnsaye(result.done.datos_muestra.forma_muestra);
                    setResistenciaProyecto(result.done.datos_muestra.resistencia);
                    setRecord(result.done.datos_ensaye);

                    if(result.done.tipo_muestra === 'Compactación') {
                        setCompactacionProyecto(result.done.datos_muestra.grado_compactacion);
                        setEspesorCapa(result.done.datos_muestra.espesor_de_capa);
                    }
                }
            }catch (e) {
                console.log(e);
            }
        }

        if(idRegistro) {
            getRecord();
        }
    },[]);

    const handleBackButton = () => {

        history.push('/capturista/ensayes');

    };

    return(
        <div>
            <Row className="text-center">
                <Col>
                    <h3>Datos de ensaye { idRegistro ? idRegistro : ''} { formaEnsaye ? '- ' + formaEnsaye : ''}</h3>

                    { tipoEnsaye === 'Compactación' ?
                        <p>Muestra: { idMuestra } - Tipo: { tipoEnsaye } - Espesor de capa: { espesorCapa } - Grado Compactación: { compactacionProyecto } </p> :
                        <p>Muestra: { idMuestra } - Edad: { edadEnsaye } - Tipo: { tipoEnsaye } - Resistencia: { resistenciaProyecto } </p>
                    }
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col sm={ 8 }>

                    { tipoEnsaye === 'Compresión' && record ?
                        <Compresion
                            record={ record }
                            idRegistro={ idRegistro }
                            formaEnsaye={ formaEnsaye }
                            resistenciaProyecto={ resistenciaProyecto }
                            setButtonGuardarDisabled={ setButtonGuardarDisabled }
                        /> : '' }

                    { tipoEnsaye === 'Compactación' && record ?
                        <Compactacion
                            record={ record }
                            idRegistro={ idRegistro }
                            formaEnsaye={ formaEnsaye }
                            resistenciaProyecto={ resistenciaProyecto }
                            setButtonGuardarDisabled={ setButtonGuardarDisabled }
                        /> : '' }

                    { tipoEnsaye === 'Blocks' && record ?
                        <Blocks
                            record={ record }
                            idRegistro={ idRegistro }
                            formaEnsaye={ formaEnsaye }
                            resistenciaProyecto={ resistenciaProyecto }
                            setButtonGuardarDisabled={ setButtonGuardarDisabled }
                        /> : '' }

                    <p className="mt-4 text-center">Los campos con * son obligatorios</p>
                    <Row className="mt-4 justify-content-between">
                        <Col sm={ 4 } className="text-right">
                            <Button onClick={() => handleBackButton() } className="secondary">
                                Cancelar
                            </Button>
                        </Col>
                        <Col sm={ 4 } className="text-left">
                            <Button type="submit" form="form-child-muestra" className="primary" disabled={ buttonGuardarDisabled }>
                                Guardar Ensaye
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
 };

export default withRouter(FormularioEnsayes);