import React, {useEffect, useState} from "react";
import { Col, Row, FormGroup, Label, Form, Button } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { customStylesSelect } from "../../../../constants/customStylesSelect";
import moment from 'moment';
import 'moment/locale/es';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {fetchRecord} from "../../../../actions/fetchRecord";
import {updateRecord} from "../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../actions/storeRecord";
import {fetchRecords} from "../../../../actions/fetchRecords";
import {useUsuario} from "../../../../context/usuario-context";

registerLocale('es', es);

const FormularioObras = props => {
    const { setBreadcrumbItems } = useUsuario();
    const { register, handleSubmit, errors } = useForm();

    const [ record, setRecord ] = useState(null);
    const [ fechaInicio, setfechaInicio ] = useState(null);
    const [ fechaFin, setFechaFin ] = useState(null);
    const [ idCliente, setidCliente ] = useState(null);
    const [ idEstado, setIdEstado ] = useState(null);
    const [ selectClientes, setSelectClientes ] = useState([]);

    const history = useHistory();
    const idRegistro  = props.match.params.idRegistro;

    const selectEstados = [ 
        { value:'Aguascalientes',label:'Aguascalientes',name:'estado' },
        { value:'Baja California',label:'Baja California',name:'estado' },
        { value:'Baja California Sur',label:'Baja California Sur',name:'estado' },
        { value:'Campeche',label:'Campeche',name:'estado' },
        { value:'Coahuila de Zaragoza',label:'Coahuila de Zaragoza',name:'estado' },
        { value:'Colima',label:'Colima',name:'estado' },
        { value:'Chiapas',label:'Chiapas',name:'estado' },
        { value:'Chihuahua',label:'Chihuahua',name:'estado' },
        { value:'Distrito Federal',label:'Distrito Federal',name:'estado' },
        { value:'Durango',label:'Durango',name:'estado' },
        { value:'Guanajuato',label:'Guanajuato',name:'estado' },
        { value:'Guerrero',label:'Guerrero',name:'estado' },
        { value:'Hidalgo',label:'Hidalgo',name:'estado' },
        { value:'Jalisco',label:'Jalisco',name:'estado' },
        { value:'México',label:'México',name:'estado' },
        { value:'Michoacán de Ocampo',label:'Michoacán de Ocampo',name:'estado' },
        { value:'Morelos',label:'Morelos',name:'estado' },
        { value:'Nayarit',label:'Nayarit',name:'estado' },
        { value:'Nuevo León',label:'Nuevo León',name:'estado' },
        { value:'Oaxaca',label:'Oaxaca',name:'estado' },
        { value:'Puebla',label:'Puebla',name:'estado' },
        { value:'Querétaro',label:'Querétaro',name:'estado' },
        { value:'Quintana Roo',label:'Quintana Roo',name:'estado' },
        { value:'San Luis Potosí',label:'San Luis Potosí',name:'estado' },
        { value:'Sinaloa',label:'Sinaloa',name:'estado' },
        { value:'Sonora',label:'Sonora',name:'estado' },
        { value:'Tabasco',label:'Tabasco',name:'estado' },
        { value:'Tamaulipas',label:'Tamaulipas',name:'estado' },
        { value:'Tlaxcala',label:'Tlaxcala',name:'estado' },
        { value:'Veracruz de Ignacio de la Llave',label:'Veracruz de Ignacio de la Llave',name:'estado' },
        { value:'Yucatán',label:'Yucatán',name:'estado' },
        { value:'Zacatecas',label:'Zacatecas',name:'estado' },
     ];

    useEffect(() => {
        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/obras',
                'item':'Obras'
            },
            {
                'item':'Agregar obra'
            }
        ]);

        async function getRecord() {
            try {
                const result = await fetchRecord(idRegistro,'obras');

                if(result) {

                    setRecord(result.done);
                    setfechaInicio(new Date(result.done.fecha_inicio));

                    if(result.done?.fecha_fin) {
                        setFechaFin(new Date(result.done.fecha_fin));
                    }

                    setidCliente(result.done.cliente_id);
                    setIdEstado(result.done.estado);

                }
            }catch (e) {
                console.log(e);
            }
        }

        async function getClientes() {
            try {
                const result = await fetchRecords('clientes');

                if(result.done) {

                    const arrClientes = [];

                    result.done.map((val) => {
                        arrClientes.push({value:val.idCliente,label:val.nombre_completo,name:'cliente_id'})
                    });

                    setSelectClientes(arrClientes);

                }
            }catch (e) {
                console.log(e);
            }
        }

        if(idRegistro) {
            getRecord();
        }

        getClientes();

    },[  ]);

    /**
     * Función onSubmit
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        data.fecha_inicio = moment(fechaInicio).format('YYYY-MM-DD');

        if(fechaFin) {
            data.fecha_fin = moment(fechaFin).format('YYYY-MM-DD');
        }

        //Si contiene el registro se entra al modo update, de lo contrario se crea un nuevo registro
        if(record) {

            try {

                const response = await updateRecord(data,'obras',idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la obra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: [ "animated", "fadeIn" ],
                        animationOut: [ "animated", "fadeOut" ],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la obra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: [ "animated", "fadeIn" ],
                    animationOut: [ "animated", "fadeOut" ],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            //Redirect al listado de obras
            history.push(`/capturista/obras`);

        }else{
            try {

                const response = await storeRecord(data,'obras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva obra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: [ "animated", "fadeIn" ],
                        animationOut: [ "animated", "fadeOut" ],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    //Redirect al listado de obras
                    history.push(`/capturista/obras`);
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la obra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: [ "animated", "fadeIn" ],
                    animationOut: [ "animated", "fadeOut" ],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    return(
        <div>
            <Row className="text-center">
                <Col>
                    <h3>{ idRegistro ? 'Editar' : 'Agregar'} obra</h3>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col sm={ 8 }>
                    <Form id="form-obra" onSubmit={ handleSubmit(onSubmit) } >
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label>* Nombre de la obra</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="nombre"
                                           defaultValue={ record ? record.nombre_obra : undefined }
                                           ref={register({required:true})}/>
                                    {errors.nombre && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Cliente</Label>
                                    <Select styles={ customStylesSelect }
                                            options={ selectClientes }
                                            placeholder="Selecciona ..."
                                            value={ selectClientes.find(op => {
                                                return op.value === idCliente
                                            }) }
                                            onChange={ (event) => {
                                                setidCliente(event.value);
                                            } }
                                    />
                                    <input
                                        tabIndex={ -1 }
                                        autoComplete="off"
                                        style={ { opacity: 0, height: 0, display: 'none' } }
                                        value={ idCliente }
                                        name="cliente_id"
                                        ref={ register({ required:true }) }
                                    />
                                    { errors.cliente_id && <small>Ingresa un cliente</small> }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label>Contratista</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="contratista"
                                           defaultValue={ record ? record.contratista : undefined }
                                           ref={register}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <p className="divider animate fadeInRight">
                            Domicilio
                        </p>
                        <hr/>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label>* Calle</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="calle"
                                           defaultValue={ record ? record.calle : undefined }
                                           ref={register({required:true})}/>
                                    {errors.calle && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Número exterior</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="numero_exterior"
                                           defaultValue={ record ? record.numero_exterior : undefined }
                                           ref={register({required:true})}/>
                                    {errors.numero_exterior && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label> Número interior</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="numero_interior"
                                           defaultValue={ record ? record.numero_interior : undefined }
                                           ref={ register }/>
                                    {errors.numero_interior && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>Código postal</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="codigo_postal"
                                           defaultValue={ record ? record.codigo_postal : undefined }
                                           ref={register}/>
                                    {errors.codigo_postal && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>Colonia</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="colonia"
                                           defaultValue={ record ? record.colonia : undefined }
                                           ref={register}/>
                                    {errors.colonia && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>Municipio</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="municipio"
                                           defaultValue={ record ? record.municipio : undefined }
                                           ref={register}/>
                                    {errors.municipio && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Estado</Label>
                                    <Select styles={ customStylesSelect }
                                            options={ selectEstados }
                                            placeholder="Selecciona ..."
                                            value={ selectEstados.find(op => {
                                                return op.value === idEstado
                                            }) }
                                            onChange={ (event) => {
                                                setIdEstado(event.value);
                                            } }
                                    />
                                    <input
                                        tabIndex={ -1 }
                                        autoComplete="off"
                                        style={ { opacity: 0, height: 0, display: 'none' } }
                                        value={ idEstado }
                                        name="estado"
                                        ref={ register({ required:true }) }
                                    />
                                    { errors.estado && <small>Ingresa un estado</small> }
                                </FormGroup>
                            </Col>
                        </Row>
                        <p className="divider animate fadeInRight">
                            Datos adicionales
                        </p>
                        <hr/>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Fecha de inicio</Label>
                                    <br/>
                                    <DatePicker
                                        locale="es"
                                        className="form-control"
                                        name="fecha"
                                        dateFormat="dd/MMMM/yyyy"
                                        selected={ fechaInicio }
                                        onChange={ date => setfechaInicio(date) } />
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>Fecha de fin</Label>
                                    <br/>
                                    <DatePicker
                                        locale="es"
                                        className="form-control"
                                        minDate={ fechaInicio }
                                        name="fecha"
                                        dateFormat="dd/MMMM/yyyy"
                                        selected={ fechaFin }
                                        onChange={ date => setFechaFin(date) } />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    
                    <p className="mt-4 text-center">Los campos con * son obligatorios</p>
                    <Row className="mt-4 justify-content-between">
                        <Col sm={ 4 } className="text-right">
                            <Link to={'/capturista/obras'}>
                                <Button className="secondary">
                                    Cancelar
                                </Button>
                            </Link>
                        </Col>
                        <Col sm={ 4 } className="text-left">
                            <Button type="submit" form="form-obra" className="primary">
                                Guardar obra
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
 };

export default withRouter(FormularioObras);