import React, {useEffect, useState} from "react";
import {Button, Col, InputGroup, InputGroupAddon, Row, CustomInput} from "reactstrap";
import {useForm} from "react-hook-form";
import './styles.scss';
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import { Redirect } from "react-router-dom";
import {fetchRecord} from "../../../../../../actions/fetchRecord";

const Seccion3 = props => {
    const { register,errors } = useForm();
    const [cantidadEnsayes,setCantidadEnsayes] = useState(1);
    const [idUltimoEnsaye,setIdUltimoEnsaye] = useState(null);
    const [itemsEnsayes,setItemsEnsayes] = useState([]);
    const [redirect,setRedirect] = useState(false);

    useEffect(() => {
        async function getUltimoEnsaye() {
            const result = await fetchRecord(null,'ensayes/ultimo');
            if(result){
                setIdUltimoEnsaye(result.id);
            }
        };

        getUltimoEnsaye();

        if(Object.keys(props.mainObjectData[1].ensayes).length !== 0) {
            const data = props.mainObjectData[1].ensayes;

            const ensayes = [];
            
            data.map(item => {
                console.log(item.dato_ensaye.muestreado_por);

                ensayes.push(itemEnsaye(item.id,true,item.molde,item.edad,item.dato_ensaye.muestreado_por));

            });

            setItemsEnsayes(ensayes); //Se establecen los items de ensayes en su arreglo
            setCantidadEnsayes(ensayes.length); //Se establece la cantidad de ensayes en el input
        }
    },[]);

    /**
     * Pobla el select de moldes con 50 elementos
     * @returns {[]}
     */
    const createTiposMolde = (value = null) => {
        const tiposMolde = [];

        for(let i = 1; i <= 50; i++) {
            tiposMolde.push(<option value={`${i}`} selected={ parseInt(value) === i }>{`Molde ${i}`}</option>);
        }

        return tiposMolde;
    };

    /**
     * Crea el JSX del row del ensaye
     * @param ensaye
     * @param bActualizando
     * @param molde
     * @param edad
     * @param muestreado
     * @returns {*}
     */
    const itemEnsaye = (ensaye, bActualizando = false, molde = null, edad = null, muestreado=null) => {

        console.log('muestreado: ',muestreado);
        return (<Row className="mt-4 animate fadeInUp one">
        <Col sm={2} className="d-flex align-items-center justify-content-end">
            Ensaye { ensaye }
        </Col>
            {/*Si está actualizando se crea un campo oculto con el id del ensaye*/}
            {bActualizando ?  <input
                tabIndex={ -1 }
                autoComplete="off"
                defaultValue={ ensaye ? ensaye : undefined }
                name="id"
                ref={ register({ required:true }) }
                hidden
            /> : ''}
        <Col sm={3}>
            <label>* Molde</label>
            <CustomInput type="select" id={`select-molde-${cantidadEnsayes}`} className="select" name="molde">
                {createTiposMolde(molde)}
            </CustomInput>
            {errors.molde && <small>Ingresa un valor</small>}
        </Col>
        <Col sm={3}>
            <label>* Edad</label>
            <input className="form-control" type="text"
                   name="edad"
                   defaultValue={edad ? edad : undefined}
                   ref={register({required:true})}
            />
            {errors.edad && <small>Ingresa un valor</small>}

        </Col>
        <Col sm={3}>
            <label>* Muestreado por</label>
            <CustomInput type="select"
                         id={`select-muestreado-${cantidadEnsayes}`}
                         className="select"
                         name="muestreado_por">
                <option value="Laboratorio" selected={ muestreado === 'Laboratorio'}>Laboratorio</option>
                <option value="Cliente" selected={ muestreado === 'Cliente'}>Cliente</option>
            </CustomInput>

            {errors.muestreado_por && <small>Ingresa un valor</small>}
        </Col>
    </Row>)};

    /**
     * Inserta un JSX del row de un ensaye en el arreglo itemsEnsayes
     */
    const pushEnsaye = () => {
        setCantidadEnsayes( cantidadEnsayes + 1 );

        let buffer = itemsEnsayes;
        setIdUltimoEnsaye(idUltimoEnsaye + 1 );

        buffer.push(
            itemEnsaye(idUltimoEnsaye + 1)
        );
        
        setCantidadEnsayes(buffer.length);
        setItemsEnsayes(buffer);
    };

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (event) => {

        event.preventDefault();

        let edadItems = event.target.elements.edad; //Edad del ensaye
        let moldeItems = event.target.elements.molde; //Molde del ensaye
        let muestreadoItems = event.target.elements.muestreado_por; //Por quién fue muestreado el ensaye

        let idEnsayes = event.target.elements.id;

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularioa anteriores

        let objEnsayes = [];

        if(idEnsayes !== undefined) {
             objEnsayes = toObjects( idEnsayes, edadItems,moldeItems,muestreadoItems ); //Se convierten a objetos los ensayes
        }else{
             objEnsayes = toObjects( null, edadItems,moldeItems,muestreadoItems ); //Se convierten a objetos los ensayes
        }

        objData[1].ensayes = objEnsayes; //se agrega el objeto principal los ensayes convertidos en objetos
        objData[1].datos_muestra.cantidad_muestras = cantidadEnsayes; //Se agrega al objeto principal la cantidad de muestras (ensayes)

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            setRedirect(true);

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    setRedirect(true);
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    /**
     * Convierte a objetos los arreglos específicados
     * @param idEnsaye
     * @param edadItems
     * @param moldeItems
     * @param muestreadoItems
     * @returns {[]}
     */
    const toObjects = ( idEnsaye = null, edadItems, moldeItems, muestreadoItems ) => {

        let arr = [];
        console.log(Object.keys(edadItems).length);

        for(let i = 0;i < cantidadEnsayes; i++) {
            //Si contiene el idEnsaye se considera en el objeto final
            let obj = {};

            if(cantidadEnsayes > 1) {
                if(idEnsaye != null) {
                    obj = {id:idEnsaye[i].value,molde:moldeItems[i].value,edad:edadItems[i].value,muestreado_por:muestreadoItems[i].value};
                }else {
                    obj = {molde:moldeItems[i].value,edad:edadItems[i].value,muestreado_por:muestreadoItems[i].value};
                }
            }else{
                if(idEnsaye != null) {
                    obj = {id:idEnsaye.value,molde:moldeItems.value,edad:edadItems.value,muestreado_por:muestreadoItems.value};
                }else {
                    obj = {molde:moldeItems.value,edad:edadItems.value,muestreado_por:muestreadoItems.value};
                }
            }

            arr.push(obj);
        }

        return arr;
    };

    return(
        <div>
            { redirect ? <Redirect to='/capturista/muestras'/> : '' }
            <Row className="mt-2 justify-content-start">
                <Col sm={4}>
                    <label>* Cantidad de ensayes</label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button className="action" onClick={() => {
                                setCantidadEnsayes( cantidadEnsayes - 1 );
                                setIdUltimoEnsaye(idUltimoEnsaye - 1 );
                                let temp = itemsEnsayes;
                                temp.pop();
                                setItemsEnsayes(temp);
                            }
                            }>
                                -
                            </Button>
                        </InputGroupAddon>
                        <input className="form-control" max={100} min={1} type="number" disabled value={cantidadEnsayes} onChange={event => (setCantidadEnsayes(event.target.value))}/>
                        <InputGroupAddon addonType="append">
                            <Button className="action" onClick={() => {

                                pushEnsaye();
                            }
                            }>
                                +
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>

            <form id="form-child-muestra" onSubmit={event => onSubmit(event)}>
                { itemsEnsayes.length > 0 ? itemsEnsayes : itemEnsaye(idUltimoEnsaye + 1) }
            </form>

        </div>
    );
};

export default Seccion3;