import React, {useEffect, useState} from "react";
import { Col, Row, FormGroup, Label, Form, Button } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { customStylesSelect } from "../../../../constants/customStylesSelect";
import moment from 'moment';
import 'moment/locale/es';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {fetchRecord} from "../../../../actions/fetchRecord";
import {updateRecord} from "../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../actions/storeRecord";
import {useUsuario} from "../../../../context/usuario-context";

registerLocale('es', es);

const FormularioClientes = props => {
    const { setBreadcrumbItems } = useUsuario();

    const [record, setRecord] = useState(null);
    const { register, handleSubmit, errors } = useForm();
    const [fechaInicio, setfechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [idCliente,setidCliente] = useState(null);
    const [idEstado,setIdEstado] = useState(null);
    const history = useHistory();
    const idRegistro  = props.match.params.idRegistro;

    useEffect(() => {
        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/clientes',
                'item':'Clientes'
            },
            {
                'item':'Agregar cliente'
            }
        ]);

        async function getRecord() {
            try {
                const result = await fetchRecord(idRegistro,'clientes');
                if(result) {

                    setRecord(result.done);
                    setfechaInicio(new Date(result.done.fecha_inicio));

                    if(result.done?.fecha_fin) {
                        setFechaFin(new Date(result.done.fecha_fin));
                    }

                    setidCliente(result.done.cliente_id);
                    setIdEstado(result.done.estado);

                }
            }catch (e) {
                console.log(e);
            }
        }

        if(idRegistro) {
            getRecord();
        }
    },[]);

    /**
     * Función onSubmit
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objCliente = {};
        let objUsuario = {};
        let payload = {};
        objCliente.nombre = data.nombre;
        objCliente.apellido_pat = data.apellido_pat;
        objCliente.apellido_mat = data.apellido_mat;
        objCliente.telefono = data.telefono;
        objCliente.cargo = data.cargo;
        objCliente.dirigido_a = data.dirigido_a;
        objCliente.nombre_empresa = data.nombre_empresa;
        objCliente.domicilio_empresa = data.domicilio_empresa;

        objUsuario.email = data.email;
        objUsuario.password = 'cliente_sm';
        objUsuario.tipo_usuario = 3;

        payload.cliente = objCliente;
        payload.usuario = objUsuario;

        //Si contiene el registro se entra al modo update, de lo contrario se crea un nuevo registro
        if(record) {

            try {

                const response = await updateRecord(payload,'clientes',idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado el cliente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar el cliente",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            //Redirect al listado de clientes
            history.push(`/capturista/clientes`);

        }else{
            try {

                const response = await storeRecord(payload,'clientes/registro');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado un nuevo cliente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    //Redirect al listado de clientes
                    history.push(`/capturista/clientes`);
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la cliente",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    return(
        <div>
            <Row className="text-center">
                <Col>
                    <h3>{ idRegistro ? 'Editar' : 'Agregar'} cliente</h3>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col sm={ 6 }>
                    <Form id="form-cliente" onSubmit={ handleSubmit(onSubmit) } >
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label>* Nombre</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="nombre"
                                           defaultValue={ record ? record.nombre : undefined }
                                           ref={register({required:true})}/>
                                    {errors.nombre && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Apellido paterno</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="apellido_pat"
                                           defaultValue={ record ? record.apellido_pat : undefined }
                                           ref={ register({required:true}) }/>
                                    {errors.apellido_pat && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Apellido materno</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="apellido_mat"
                                           defaultValue={ record ? record.apellido_mat : undefined }
                                           ref={ register({required:true}) }/>
                                    {errors.apellido_mat && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label>* Teléfono </Label>
                                    <input className="form-control"
                                           type="text"
                                           name="telefono"
                                           defaultValue={ record ? record.telefono : undefined }
                                           ref={ register({required:true}) }/>
                                    {errors.telefono && <small>Ingresa un valor</small>}
                                </FormGroup>
                            </Col>
                            <Col sm={ 4 }>
                                <FormGroup>
                                    <Label> Cargo </Label>
                                    <input className="form-control"
                                           type="text"
                                           name="cargo"
                                           defaultValue={ record ? record.cargo : undefined }
                                           ref={ register }/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label> A quién va dirigido </Label>
                                    <input className="form-control"
                                           type="text"
                                           name="dirigido_a"
                                           defaultValue={ record ? record.dirigido_a : undefined }
                                           ref={ register }/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <p className="divider animate fadeInRight">
                            Datos de la empresa
                        </p>
                        <hr/>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label> Nombre de la empresa </Label>
                                    <input className="form-control"
                                           type="text"
                                           name="nombre_empresa"
                                           defaultValue={ record ? record.nombre_empresa : undefined }
                                           ref={ register }/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label> Domicilio de la empresa </Label>
                                    <input className="form-control"
                                           type="text"
                                           name="domicilio_empresa"
                                           defaultValue={ record ? record.domicilio_empresa : undefined }
                                           ref={ register }/>
                                </FormGroup>
                            </Col>
                        </Row>

                        <p className="divider animate fadeInRight">
                            Datos de inicio de sesión
                        </p>
                        <hr/>
                        <Row>
                            <Col sm={ 8 }>
                                <FormGroup>
                                    <Label>* Correo electrónico</Label>
                                    <input className="form-control"
                                           type="text"
                                           name="email"
                                           defaultValue={ record ? record.email : undefined }
                                           ref={register({required:true})}/>
                                    {errors.email && <small>Ingresa un correo</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={ 8 }>
                                <p>La contraseña por defecto es <b>cliente_sm</b> <br/>
                                    el cliente puede elegir su contraseña la primera vez que inicie sesión</p>
                            </Col>
                        </Row>
                    </Form>
                    
                    <p className="mt-4 text-center">Los campos con * son obligatorios</p>
                    <Row className="mt-4 justify-content-between">
                        <Col sm={ 4 } className="text-right">
                            <Link to={'/capturista/clientes'}>
                                <Button className="secondary">
                                    Cancelar
                                </Button>
                            </Link>
                        </Col>
                        <Col sm={ 4 } className="text-left">
                            <Button type="submit" form="form-cliente" className="primary">
                                Guardar cliente
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
 };

export default withRouter(FormularioClientes);