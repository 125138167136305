import React from "react";
import styled from 'styled-components';

const colorBlackCoral = '#545B62';
const colorDevysGray = '#384042';
const colorSeaGreen = '#18F2B2';
const colorBlue = '#1D6AAE';
const colorDanger = '#FF5C5C';

const StyledButton = (styled.button`
    color: ${props => props?.tema?.color ? props?.tema.color : colorBlue};
    background: ${props => props?.tema?.background ? props?.tema.background : '#fff'};
    border-radius: 30px;
    padding: ${props => props.padding ? props.padding : '0.6rem 2rem'};
    font-size: ${props => props.font ? props.font : '1rem'};
    
    border: none;
    transition: 150ms;
    
    &.danger {
      color: ${colorDanger};
      border: solid 1px ${colorDanger};
      &:hover {
        border-bottom: solid 2px ${colorDanger};
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    
      &:active {
        border-bottom: solid 1px ${colorDanger};
      }
    }
    
    &.basic {
      border: solid 1px ${colorBlue};
      
      &:hover {
        border-bottom: solid 2px ${colorBlue};
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    
      &:active {
        border-bottom: solid 1px ${colorBlue};
      }
    }
    
    &.small {
      font-size: 0.8rem;
      padding: 0.2rem 1rem;
    }
    
    &.white {
      background-color: white !important;
      color: ${colorBlue};
      border: solid 1px ${colorBlue};
      
      &:hover {
        border-bottom: solid 2px ${colorBlue};
      }
      
      &:active {
        background-color: white;
        border-bottom: solid 1px ${colorBlue};
      }
    }
    
    &.theme {
       background-color: white;
    }
    
    &.gray {
      background-color: ${colorBlackCoral} !important;
      color: white;
      
      &:hover {
        border-bottom: solid 3px ${colorSeaGreen};
      }
      
      &:active {
        background-color: ${colorDevysGray};
        border-bottom: solid 1px ${colorSeaGreen};
      }
    }
    
    &.blue {
      background-color: white;
      color: ${colorBlue};
      border: solid 1px ${colorBlue};
      
      &:hover {
        border-bottom: solid 2px ${colorBlue};
      }
      
      &:active {
        background-color: white;
        border-bottom: solid 1px ${colorBlue};
      }
    }
    
    &:hover {
      border-bottom: solid 4px ${colorSeaGreen};
    }
    
    &:active {
       border-bottom: solid 2px ${colorSeaGreen};
    }
    
`);

export default StyledButton;