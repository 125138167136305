import React  from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {url_base} from "../../../../constants/api_url";
import StyledButton from "../../button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import { printPlugin,RenderPrintProps } from '@react-pdf-viewer/print';
import '@react-pdf-viewer/print/lib/styles/index.css';
import {Viewer} from "@react-pdf-viewer/core";

const ModalImprimirReporte = props => {
    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    return(<Modal isOpen={props.modalControl} toggle={() => props.toggleModalControl(!props.modalControl)} className={props.className}>
        <ModalHeader toggle={() => props.toggleModalControl()}>Imprimir reporte {props.idReporte}</ModalHeader>
        <ModalBody className="text-center">
            <Viewer
                fileUrl={`${url_base}reportes/${props.idReporte}/1`}
                plugins={[
                    printPluginInstance,
                ]}
            />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
            <Print>
                {
                    (props) => (
                        <StyledButton
                            className="gray"
                            onClick={props.onClick}
                        >
                            <FontAwesomeIcon icon={faPrint}/>{' '}
                               Imprimir
                        </StyledButton>
                    )
                }
            </Print>
        </ModalFooter>
    </Modal>)};

export default ModalImprimirReporte;

