import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import {CapturistaContainerWithRouter} from "../components/capturista";
import Ensayes from "../components/capturista/ensayes";
import BienvenidaCapturista from "../components/capturista/bienvenida";
import BienvenidaAdmin from "../components/admin/bienvenida";
import ClientesCapturista from "../components/capturista/clientes";
import ClientesAdmin from "../components/admin/clientes";
import Muestras from "../components/capturista/muestras";
import Obras from "../components/capturista/obras";
import FormularioMuestras from "../components/capturista/muestras/formulario";
import FormularioEnsayes from "../components/capturista/ensayes/formulario";
import FormularioObras from "../components/capturista/obras/formulario";
import FormularioClientesAdmin from "../components/admin/clientes/formulario";
import FormularioClientesCapturista from "../components/capturista/clientes/formulario";
import Login from "../components/login";
import {AdminContainerWithRouter} from "../components/admin";
import {useUsuario} from "../context/usuario-context";
import {getUser} from "../actions/getUser";
import CookieService from "../services/CookieService";
import Capturistas from "../components/admin/capturistas";
import FormularioCapturista from "../components/admin/capturistas/formulario";
import EnsayesCompactacion from "../components/capturista/ensayes/compactacion";
import EnsayesAcero from "../components/capturista/ensayes/acero";
import EnsayesBlock from "../components/capturista/ensayes/block";
import EnsayesCalidades from "../components/capturista/ensayes/calidades";
import EnsayesConcreto from "../components/capturista/ensayes/concreto";
import EnsayesMortero from "../components/capturista/ensayes/mortero";
import EnsayesGranulometria from "../components/capturista/ensayes/granulometria";
import EnsayesPesoVolumetrico from "../components/capturista/ensayes/pesoVolumetrico";
import MuestrasCompactacion from "../components/capturista/muestras/compactacion";
import MuestrasAcero from "../components/capturista/muestras/acero";
import MuestrasBlock from "../components/capturista/muestras/block";
import MuestrasCalidades from "../components/capturista/muestras/calidades";
import MuestrasConcreto from "../components/capturista/muestras/concreto";
import MuestrasMortero from "../components/capturista/muestras/mortero";
import MuestrasGranulometria from "../components/capturista/muestras/granulometria";
import MuestrasPesoVolumetrico from "../components/capturista/muestras/pesoVolumetrico";
import DetallesMuestra from "../components/capturista/muestras/detalles";
import Reportes from "../components/common/reportes";
import GenerarReporte from "../components/common/reportes/generar";

const AppRoutes = () => {
    const { tipoUsuario, setUserLoggedIn,setUsuario,setTipoUsuario,userLoggedIn } = useUsuario();

    useEffect(() => {
        async function auth() {
            const accessToken = CookieService.get('access_token');

            //Si el token existe carga el usuario en el contexto
            if(accessToken === undefined) {
                setUserLoggedIn(false);
                return;
            }

            try {
                //Se obtiene una respuesta del servidor con los datos del usuario, de existir se setea en el contexto
                const response = await getUser(accessToken);
                if(response) {
                    setUsuario(response);
                    setTipoUsuario(response.user.tipo_usuario);
                }
            }catch (err) {
                console.log(err);
            }

        }

        //Solo se realiza la comprobacion del usuario si no se encuentra en /
        if(window.location.pathname !== '/') {
            auth();
        }

    },[]);

    const AdminRoutes = (
        <AdminContainerWithRouter>
            <Route exact path="/admin" component={BienvenidaAdmin}/>
            <Route exact path="/admin/capturistas" component={Capturistas}/>
            <Route exact path="/admin/capturistas/agregar" component={FormularioCapturista}/>
            <Route exact path="/admin/capturistas/editar/:idRegistro" component={FormularioCapturista}/>
            <Route exact path="/admin/clientes" component={ClientesAdmin}/>
            <Route exact path="/admin/clientes/agregar" component={FormularioClientesAdmin}/>
            <Route exact path="/admin/clientes/editar/:idRegistro" component={FormularioClientesAdmin}/>
        </AdminContainerWithRouter>
    );

    const CapturistaRoutes = (
        <CapturistaContainerWithRouter>
            <Route exact path="/capturista" component={BienvenidaCapturista}/>
            <Route exact path="/capturista/clientes" component={ClientesCapturista}/>
            <Route exact path="/capturista/clientes/agregar" component={FormularioClientesCapturista}/>
            <Route exact path="/capturista/clientes/editar/:idRegistro" component={FormularioClientesCapturista}/>
            <Route exact path="/capturista/ensayes" component={Ensayes}/>
            <Route exact path="/capturista/ensayes/compactacion" component={EnsayesCompactacion}/>
            <Route exact path="/capturista/ensayes/acero" component={EnsayesAcero}/>
            <Route exact path="/capturista/ensayes/Blocks/:idMuestra?" component={EnsayesBlock}/>
            <Route exact path="/capturista/ensayes/calidades" component={EnsayesCalidades}/>
            <Route exact path="/capturista/ensayes/concreto" component={EnsayesConcreto}/>
            <Route exact path="/capturista/ensayes/mortero" component={EnsayesMortero}/>
            <Route exact path="/capturista/ensayes/granulometria" component={EnsayesGranulometria}/>
            <Route exact path="/capturista/ensayes/pesoVolumetrico" component={EnsayesPesoVolumetrico}/>
            <Route exact path="/capturista/ensayes/revisar/:idRegistro" component={FormularioEnsayes}/>
            <Route exact path="/capturista/muestras" component={Muestras}/>
            <Route exact path="/capturista/muestras/Compactación" component={MuestrasCompactacion}/>
            <Route exact path="/capturista/muestras/Acero" component={MuestrasAcero}/>
            <Route exact path="/capturista/muestras/Blocks" component={MuestrasBlock}/>
            <Route exact path="/capturista/muestras/Calidad" component={MuestrasCalidades}/>
            <Route exact path="/capturista/muestras/Concreto" component={MuestrasConcreto}/>
            <Route exact path="/capturista/muestras/Morteros" component={MuestrasMortero}/>
            <Route exact path="/capturista/muestras/Granulometría" component={MuestrasGranulometria}/>
            <Route exact path="/capturista/muestras/Peso Volumétrico Seco Máximo" component={MuestrasPesoVolumetrico}/>
            <Route exact path="/capturista/muestras/agregar/:tipoMuestra?" component={FormularioMuestras}/>
            <Route exact path="/capturista/muestras/detalles/:idRegistro" component={DetallesMuestra}/>
            <Route exact path="/capturista/muestras/editar/:idRegistro" component={FormularioMuestras}/>
            <Route exact path="/capturista/reportes/:idMuestra/:tipoMuestra" component={Reportes}/>
            <Route exact path="/capturista/reportes/generar/:idMuestra/:firmas" component={GenerarReporte}/>
            <Route exact path="/capturista/obras" component={Obras}/>
            <Route exact path="/capturista/obras/agregar" component={FormularioObras}/>
            <Route exact path="/capturista/obras/editar/:idRegistro" component={FormularioObras}/>
        </CapturistaContainerWithRouter>
    );

    return(
        <div>
            <ReactNotification/>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Login}/>

                    { tipoUsuario === 1 && AdminRoutes }
                    { tipoUsuario === 2 && CapturistaRoutes }

                    { userLoggedIn === false && <Route component={Login} /> }


                </Switch>
            </BrowserRouter>
        </div>
    );
}

;

export default AppRoutes;