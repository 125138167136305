import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";

const Seccion2 = props => { 
    const { register,handleSubmit,errors } = useForm();
    const [idTipoTermometro,setIdTipoTermometro] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const tiposTermometro = [
        { value:'T1',label:'T1',name:'termometro' },
        { value:'T2',label:'T2',name:'termometro' },
        { value:'T3',label:'T3',name:'termometro' },
        { value:'T4',label:'T4',name:'termometro' },
        { value:'T5',label:'T5',name:'termometro' },
        { value:'T6',label:'T6',name:'termometro' },
        { value:'T7',label:'T7',name:'termometro' },
        { value:'T8',label:'T8',name:'termometro' },
        { value:'T9',label:'T9',name:'termometro' },
        { value:'T10',label:'T10',name:'termometro' },
    ];

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
            setIdTipoTermometro(data.termometro);

        }
    },[]);
    
    const onSubmit = (data) => { 
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(3);
     };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Lote</Label>
                        <input className="form-control"
                               type="text"
                               name="lote"
                               defaultValue={ dataForm ? dataForm.lote : undefined }
                                                ref={ register }/>      
                 { errors.lote && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Nivel</Label>
                        <input className="form-control"
                               type="text"
                               name="nivel"
                               defaultValue={ dataForm?.nivel ? dataForm.nivel : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Etapa</Label>
                        <input className="form-control"
                               type="text"
                               name="etapa"
                               defaultValue={ dataForm ? dataForm.etapa : undefined }
                               ref={ register }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Termómetro</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposTermometro }
                                placeholder="Selecciona ..."
                                value={ tiposTermometro.find(op => { 
                                    return op.value === idTipoTermometro
                                 }) }
                                onChange={ (event) => { setIdTipoTermometro(event.value) } }
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoTermometro }
                            name="termometro"
                            ref={ register }
                        />
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Elemento</Label>
                        <input className="form-control"
                               type="text"
                               name="elemento"
                               defaultValue={ dataForm ? dataForm.elemento : undefined }
                               ref={ register }
                        />

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Temperatura</Label>
                        <input className="form-control"
                               type="text"
                               name="temperatura"
                               defaultValue={  dataForm?.temperatura ?  dataForm?.temperatura : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Manzana</Label>
                        <input className="form-control"
                               type="text"
                               name="manzana"
                               defaultValue={  dataForm?.manzana ?  dataForm?.manzana : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Cadenamiento</Label>
                        <input className="form-control"
                               type="text"
                               name="cadenamiento"
                               defaultValue={  dataForm?.cadenamiento ?  dataForm?.cadenamiento : undefined }
                               ref={ register }/>

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Eje</Label>
                        <input className="form-control"
                               type="text"
                               name="eje"
                               defaultValue={  dataForm?.eje ?  dataForm?.eje : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Revenimiento de obra</Label>
                        <input className="form-control"
                               type="text"
                               name="revenimiento_2"
                               defaultValue={  dataForm?.revenimiento_2 ?  dataForm?.revenimiento_2 : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Notas</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="notas"
                                  defaultValue={ dataForm?.notas ?  dataForm?.notas : undefined }
                                                   ref={ register }/>

                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion2;