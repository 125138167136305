import React  from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import {useHistory} from 'react-router-dom';

const ModalDescargaMasiva = props => {
    const history = useHistory();

    return(<Modal isOpen={props.modalControl} toggle={() => props.toggleModalControl()} className={props.className}>
        <ModalHeader toggle={() => props.toggleModalControl()}>Descarga masiva de reportes</ModalHeader>
        <ModalBody>
            <p>¿Desea descargar los reportes de la tabla con o sin Firmas?</p>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={() => {history.push(`/capturista/reportes/generar/${props.idMuestra}/0`)}}>Sin Firmas</Button>
            <Button color="primary" onClick={() => {history.push(`/capturista/reportes/generar/${props.idMuestra}/1`)}}>Con Firmas</Button>{' '}
        </ModalFooter>
    </Modal>)};

export default ModalDescargaMasiva;

