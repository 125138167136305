import stringifyData from "./../services/stringifyData";
import {url_base} from "./../constants/api_url";
import axios from "axios";
import CookieService from "../services/CookieService";
import 'react-notifications-component/dist/theme.css'

const api_url = url_base;

export const storeRecord = async (payload, resource ) => {
    const authToken = CookieService.get('access_token');

    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
    };

    if(authToken) {
        headers.Authorization = `Bearer ${authToken}`
    }

    const options = {
        url:`${api_url}${resource}`,
        method: 'POST',
        headers: headers,
        data: stringifyData(payload)
    };

    try {
         const response = await axios(options);

         if(response) {
             return response.data;
         }
    }catch (e) {
        if(e.response.status === 401) {
            window.location.href = '/';
        }else{
            console.log(e);
        }
    }
};