import React, {useEffect }  from "react";
import {UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,InputGroup,InputGroupAddon,NavLink, Collapse, Navbar, Button} from "reactstrap";
import './styles.scss';
import CookieService from "../../../services/CookieService";
import { useUsuario } from "../../../context/usuario-context";
import {useHistory} from "react-router-dom";
import DatePicker from "react-datepicker";
import Breadcrumb from "../breadcrumb";

const Header = props => {
    const history = useHistory();
    const { tema,filterBar, tipoUsuario, fechaInicioFiltro, setFechaInicioFiltro, fechaFinFiltro, setFechaFinFiltro } = useUsuario();

    useEffect(() => {
        let elements = document.querySelectorAll('[data-toggle="sticky-onscroll"]');

        [].forEach.call(elements, function(element) {

            let div = document.createElement('div');
            div.classList.add('sticky-wrapper');

            let sticky = element;
            let stickyWrapper = div;
            sticky.before(stickyWrapper);
            sticky.classList.add('sticky');

            // Scroll & resize events
            window.addEventListener('scroll', function () {
                stickyToggle(sticky, stickyWrapper, window);
            });

            // On page load
            stickyToggle(sticky, stickyWrapper, window);
        });


    },[]);

    const stickyToggle = (sticky, stickyWrapper, scrollElement) => {

        let stickyHeight = sticky.offsetHeight;
        let stickyTop = stickyWrapper.offsetTop;

        if (scrollElement.pageYOffset >= stickyTop) {
            sticky.classList.add("is-sticky");

            stickyWrapper.style.height = stickyHeight + 'px';
        } else {
            sticky.classList.remove("is-sticky");
            stickyWrapper.style.height = 'auto';
        }
    };

    const cerrarSesion = () => {

        CookieService.remove('access_token');
        CookieService.remove('tipoUsuario');

        history.push('/');
    };

    return(
        <header className="main-header">
            <Navbar style={{background:tema.background}} className={'header-dashboard navbar navbar-expand-xl navbar-light top-navbar animate fadeInDown one '} data-toggle="sticky-onscroll">
                <div className="container">

                    <NavLink className="navbar-brand d-none d-lg-inline" to="#">SM Ingeniería</NavLink>
                    <span className="d-none d-lg-block" style={{color:'white'}}>|</span>

                    <Breadcrumb/>

                    <Collapse isOpen={props.isOpen} className="navbar-collapse justify-content-center" id="navbarSupportedContent" navbar>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink href="#"
                                         className={(props.activeTab === 1 ? 'active' : '') + ' nav-link'}
                                >

                                </NavLink>
                            </li>
                        </ul>
                    </Collapse>
                    <UncontrolledDropdown>
                        <DropdownToggle caret>
                            {tipoUsuario === 1 ? 'Admin' : 'Usuario'}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem divider />
                            <DropdownItem onClick={cerrarSesion}>Cerrar Sesión</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </Navbar>

            {filterBar && <Navbar className="filter-bar navbar navbar-expand-xl navbar-light top-navbar animate fadeInDown one">
                <ul className="navbar-nav w-100 justify-content-start px-2 px-lg-3">

                    <li className="nav-item mt-0 mt-lg-1">
                        <small>
                            Filtrar por fecha:
                        </small>
                    </li>
                    <li className="nav-item ml-0 ml-lg-3 d-flex">

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <DatePicker
                                    locale="es"
                                    className="form-control filtro"
                                    name="fecha"
                                    placeholderText="inicio"
                                    dateFormat="dd/MMMM/yyyy"
                                    selected={ fechaInicioFiltro }
                                    onChange={ date => setFechaInicioFiltro(date) } />
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <DatePicker
                                    locale="es"
                                    className="form-control filtro"
                                    name="fecha"
                                    dateFormat="dd/MMMM/yyyy"
                                    placeholderText="Fin"
                                    minDate={ fechaInicioFiltro }
                                    selected={ fechaFinFiltro }
                                    onChange={ date => setFechaFinFiltro(date) } />
                            </InputGroupAddon>

                        </InputGroup>
                    </li>
                </ul>
            </Navbar>
            }


        </header>
    );
};

export default Header;