import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [idStatusCalidad,setIdStatusCalidad] = useState(undefined);
    const [idQuienEntrega,setIdQuienEntrega] = useState(undefined);
    const [idTipoCalidad,setIdTipoCalidad] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const estatusCalidad = [
        { value:'Normal',label:'Normal',name:'estatus' },
        { value:'Urgente',label:'Urgente',name:'estatus' },
    ];

    const quienEntrega = [
        { value:'Cliente',label:'Cliente',name:'quien_entrega' },
        { value:'Laboratorista',label:'Laboratorista',name:'quien_entrega' },
        { value:'Supervisor',label:'Supervisor',name:'quien_entrega' },
        { value:'Gerente',label:'Gerente',name:'quien_entrega' },
        { value:'Director',label:'Director',name:'quien_entrega' },
    ];

    const tiposCalidad = [
        { value:'Base hidráulica para pavimento de asfalto',label:'Base hidráulica para pavimento de asfalto',name:'tipo_calidad' },
        { value:'Base hidráulica para pavimento de concreto',label:'Base hidráulica para pavimento de concreto',name:'tipo_calidad' },
        { value:'Sub base',label:'Sub base',name:'tipo_calidad' },
        { value:'Subrasante',label:'Subrasante',name:'tipo_calidad' },
        { value:'Subyacente',label:'Subyacente',name:'tipo_calidad' },
        { value:'Terraplen',label:'Terraplen',name:'tipo_calidad' },
        { value:'Arena para concreto',label:'Arena para concreto',name:'tipo_calidad' },
        { value:'Grava para concreto 20mm',label:'Grava para concreto 20mm',name:'tipo_calidad' },
        { value:'Grava para concreto 40mm',label:'Grava para concreto 40mm',name:'tipo_calidad' },
        { value:'Grava para concreto 10 a 2.5',label:'Grava para concreto 10 a 2.5',name:'tipo_calidad' },
        { value:'Grava para concreto 13 a 5',label:'Grava para concreto 13 a 5',name:'tipo_calidad' },
        { value:'Grava para concreto 20 a 5',label:'Grava para concreto 20 a 5',name:'tipo_calidad' },
        { value:'Grava para concreto 20 a 10',label:'Grava para concreto 20 a 10',name:'tipo_calidad' },
        { value:'Grava para concreto 25 a 5',label:'Grava para concreto 25 a 5',name:'tipo_calidad' },
        { value:'Grava para concreto 25 a 10',label:'Grava para concreto 25 a 10',name:'tipo_calidad' },
        { value:'Grava para concreto 25 a 13',label:'Grava para concreto 25 a 13',name:'tipo_calidad' },
        { value:'Grava para concreto 40 a 5',label:'Grava para concreto 40 a 5',name:'tipo_calidad' },
        { value:'Grava para concreto 40 a 20',label:'Grava para concreto 40 a 20',name:'tipo_calidad' },
        { value:'Grava para concreto 50 a 5',label:'Grava para concreto 50 a 5',name:'tipo_calidad' },
        { value:'Grava para concreto 50 a 25',label:'Grava para concreto 50 a 25',name:'tipo_calidad' },
        { value:'Grava para concreto 64 a 40',label:'Grava para concreto 64 a 40',name:'tipo_calidad' },
        { value:'Grava para concreto 90 a 40',label:'Grava para concreto 90 a 40',name:'tipo_calidad' },
    ];

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
            setIdStatusCalidad(props.mainObjectData[1].datos_muestra.estatus);
            setIdQuienEntrega(props.mainObjectData[1].datos_muestra.quien_entrega);
            setIdTipoCalidad(props.mainObjectData[1].datos_muestra.tipo_calidad);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Banco</Label>
                        <input className="form-control"
                               type="text"
                               name="banco"
                               defaultValue={ dataForm ? dataForm.banco : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.banco && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Estatus</Label>
                        <Select styles={ customStylesSelect }
                                options={ estatusCalidad }
                                placeholder="Selecciona ..."
                                value={estatusCalidad.find(op => {
                                    return op.value === idStatusCalidad
                                })}
                                onChange={(event) => {setIdStatusCalidad(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idStatusCalidad }
                            name="estatus"
                            ref={ register({ required:true }) }
                        />
                        { errors.estatus && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Quién entrega</Label>
                        <Select styles={ customStylesSelect }
                                options={ quienEntrega }
                                placeholder="Selecciona ..."
                                value={quienEntrega.find(op => {
                                    return op.value === idQuienEntrega
                                })}
                                onChange={(event) => {setIdQuienEntrega(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idQuienEntrega }
                            name="quien_entrega"
                            ref={ register({ required:true }) }
                        />
                        { errors.quien_entrega && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Descripción</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="descripcion"
                                  defaultValue={ dataForm?.descripcion ?  dataForm?.descripcion : undefined }
                                  ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Observaciones</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="observaciones"
                                  defaultValue={ dataForm?.observaciones ?  dataForm?.observaciones : undefined }
                                  ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <FormGroup>
                        <Label>* Tipo calidad</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposCalidad }
                                placeholder="Selecciona ..."
                                value={tiposCalidad.find(op => {
                                    return op.value === idTipoCalidad
                                })}
                                onChange={(event) => {setIdTipoCalidad(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoCalidad }
                            name="tipo_calidad"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_calidad && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion1;