import {Button, ButtonGroup, Col, Row} from "reactstrap";
import React from "react";
import {useHistory} from 'react-router-dom';
import './styles.scss';

export const Menu = props => {
    const history = useHistory();

    return(
        <Row className="mt-4">
            <Col className="d-flex justify-content-center">
                <ButtonGroup>
                    <Button active={props.activeItem === 'Todos'} className="group" onClick={() =>
                        history.push('/capturista/ensayes')
                    }>Todos</Button>
                    <Button disabled active={props.activeItem === 'Compactación'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/compactacion')
                    }>Compactación</Button>
                    <Button disabled active={props.activeItem === 'Acero'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Acero')
                    }>Acero</Button>
                    <Button active={props.activeItem === 'Blocks'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Blocks')
                    }>Block</Button>
                    <Button disabled active={props.activeItem === 'Calidad'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Calidad')
                    }>Calidades</Button>
                    <Button disabled active={props.activeItem === 'Concreto'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Concreto')
                    }>Concreto</Button>
                    <Button disabled active={props.activeItem === 'Morteros'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Morteros')
                    }>Mortero</Button>
                    <Button disabled active={props.activeItem === 'Granulometría'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Granulometría')
                    }>Granulometría</Button>
                    <Button disabled active={props.activeItem === 'Peso Volumétrico Seco Máximo'} className="group" onClick={() =>
                        history.push('/capturista/ensayes/Peso Volumétrico Seco Máximo')
                    }>Peso volumétrico</Button>
                </ButtonGroup>
            </Col>
        </Row>);
};