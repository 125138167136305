import React from "react";
import Ensayes from "../index";

const url = 'ensayes-tipo/Acero';
const tituloTabla = 'Ensayes pendientes de Acero';
const tipoMuestra = 'Acero';

const EnsayesAcero = () => (
    <Ensayes
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default EnsayesAcero;