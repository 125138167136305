import React, {useEffect, useState} from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import './styles.scss';
import {Link} from "react-router-dom";
import {fetchRecords} from "../../../actions/fetchRecords";
import {useUsuario} from "../../../context/usuario-context";
import StyledButton from "../../common/button";

const Bienvenida = () => {

    const { setBreadcrumbItems, tema } = useUsuario();
    const [ cantidades, setCantidades ] = useState({});

    useEffect(() => {
        setBreadcrumbItems([
            {
                'item':'Tablero de control'
            }
        ]);
    },[]);

    useEffect(() => {
        async function getCantidades() {
            try {
                const result = await fetchRecords('capturista/countRegistros');

                if(result) {
                    setCantidades({
                        ...result.done
                    })
                }

                console.log(cantidades)
            }catch (e) {
                console.log(e);
            }
        }

        getCantidades();
    },[]);

    return(
        <div>
            <Row className="mt-4 text-center">
                <Col>
                    <h4 className="d-lg-none mt-5">Bienvenido a SM Ingeniería</h4>
                    <h3 className="d-none d-lg-inline mt-5">Bienvenido a SM Ingeniería</h3>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="mt-2 mt-lg-0" sm="6">
                    <Card className="shadow">
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Ensayes</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#ED7D3A'}}>{cantidades.ensayes}</h1>
                            <Link to={'capturista/ensayes'}>
                                <StyledButton tema={tema} className="mt-2">
                                    Ver ensayes
                                </StyledButton>
                            </Link>

                        </CardBody>
                    </Card>
                </Col>
                <Col className="mt-2 mt-lg-0" sm="6">
                    <Card className="shadow" >
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Muestras</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#1D6AAE'}}>{cantidades.muestras}</h1>
                            <Link to={'capturista/muestras'}>
                                <StyledButton tema={tema} className="mt-2">
                                    Ver muestras
                                </StyledButton>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mt-2 mt-lg-4" sm="6">
                    <Card className="shadow">
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Obras</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#1D6AAE'}}>{cantidades.obras}</h1>
                            <Link to={'capturista/obras'}>
                                <StyledButton tema={tema} className="mt-2">
                                    Ver obras
                                </StyledButton>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mt-2 mt-lg-4" sm="6">
                    <Card className="shadow" >
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Clientes</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#1D6AAE'}}>{cantidades.clientes}</h1>
                            <Link to={'capturista/clientes'}>
                                <StyledButton tema={tema} className="mt-2">
                                    Ver clientes
                                </StyledButton>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default Bienvenida;
