import React from "react";
import Ensayes from "../index";

const url = 'ensayes-tipo/Blocks';
const tituloTabla = 'Ensayes de muestras tipo Blocks';
const tipoMuestra = 'Blocks';

const EnsayesBlocks = props => {
    const idMuestra  = props?.match?.params?.idMuestra;

    return(<Ensayes
        url={`${url}${idMuestra ? '/' + idMuestra : ''}`}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />)
};

export default EnsayesBlocks;