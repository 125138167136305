import React, {useEffect, useState} from "react";
import { Row, Col, Button } from "reactstrap";
import './styles.scss';
import {useHistory} from "react-router-dom";
import {muiTableOptions} from "../../../constants/muiTableOptions";
import {fetchRecords} from "../../../actions/fetchRecords";
import MUIDataTable from "mui-datatables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardCheck, faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {Menu} from "./common/menu";
import FiltroFecha from "../../common/filtroFecha";
import {useUsuario} from "../../../context/usuario-context";

const Ensayes = props => {
    const { setBreadcrumbItems } = useUsuario();
    const url = props.url ? props.url : 'ensayes';
    const tituloTabla = props.tituloTabla ? props.tituloTabla : 'Ensayes pendientes';
    const tipoMuestra = props.tipoMuestra ? props.tipoMuestra : 'Todos';

    const [records,setRecords] = useState([]);
    const history = useHistory();

    useEffect(() => {
        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'item': props.tituloTabla
            }
        ]);
        getRecords();
    },[]);

    const getRecords = async () => {
        try {

            const result = await fetchRecords(url);

            if(result) {
                setRecords(result.done);
            }


        }catch (e) {
            console.log(e);
        }
    };

    const columns = [{
        name: "ensaye",
        label: "Ensaye",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "muestra",
        label: "Muestra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "obra",
        label: "Obra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "edad",
        label: "Edad (Días)",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "fecha_de_ensaye",
        label: "Fecha de ensaye",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "molde",
        label: "Molde",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "dias_extra",
        label: "Días extra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "porcentaje_resistencia",
        label: "% Resistencia",
        options: {
            filter: false,
            sort: true,
        }
    },{
        name: "cumple_ensaye",
        label: "Cumple",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "bEnsayado",
        label: "Ensayado",
        options: {
            filter: true,
            sort: true,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                        {tableMeta.rowData[9] ? 'Si' : 'No'}
                    </div>
                );
            }
        }
    },{
        name: "id",
        label: "Acciones",
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                        <Button type="Button" title="Revisar Ensaye" onClick={() => {

                            //Redirect al formulario de edición
                            history.push(`/capturista/ensayes/revisar/${tableMeta.rowData[0]}`);

                        }} className="mr-2 btnAction">
                            <FontAwesomeIcon
                                style={
                                     tableMeta.rowData[9] ? {color:'#2FBF71'} : {}
                                }
                                icon={ tableMeta.rowData[9] ? faClipboardCheck : faClipboardList}/>
                        </Button>
                    </div>
                );
            }
        }
    }];

    return(
        <div>
            <Row className="mt-4 text-center">
                <Col>
                    <h4 className="d-lg-none mt-5">{tituloTabla}</h4>
                    <h3 className="d-none d-lg-inline mt-5">{tituloTabla}</h3>
                </Col>
            </Row>
            <Menu activeItem={tipoMuestra}/>
            <Row className="mt-5">
                <Col>
                    <FiltroFecha
                        setRecords={setRecords}
                        url={url}
                    />
                </Col>
            </Row>
            <Row className="mt-4">

                <Col>
                    <MUIDataTable
                        title={tituloTabla}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default Ensayes;
