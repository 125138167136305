import React from "react";
import {Link} from "react-router-dom";
import './styles.scss';
import {useUsuario} from "../../../context/usuario-context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = () => {

    const {breadcrumbItems} = useUsuario();

    const breadcrumb = [];

    breadcrumbItems.map((item) => {
        if(item?.link) {
            breadcrumb.push(<Link className="ml-0 ml-lg-3 breadcrumb-link" style={{fontSize:'15px'}} to={item.link}>{item.item}</Link>);
            breadcrumb.push(<FontAwesomeIcon icon={faChevronRight} style={{color:'white',fontSize:'10px'}} />);
        }else{
            breadcrumb.push(<a className="ml-0 ml-lg-3 breadcrumb-item" style={{fontSize:'15px'}}>{item.item}</a>);
        }
    });

    return(
        <div className="ml-3 d-flex row align-items-baseline">
            {breadcrumb}
        </div>
    );

};

export default Breadcrumb;