import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import {useHistory, withRouter} from "react-router-dom";

const Seccion3 = props => {
    const { register,handleSubmit,errors } = useForm();
    const [idTipoEstructura,setIdTipoEstructura] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);
    const [compactacion,setCompactacion] = useState(null);

    const tiposEstructura = [
        { value:'Base', label:'Base', name:'tipo_estructura' },
        { value:'Sub base', label:'Sub base', name:'tipo_estructura' },
        { value:'Sub rasante', label:'Sub rasante', name:'tipo_estructura' },
        { value:'Sub yacente', label:'Sub yacente', name:'tipo_estructura' },
        { value:'Terraplen', label:'Terraplen', name:'tipo_estructura' },
        { value:'Terreno natural', label:'Terreno natural', name:'tipo_estructura' },
        { value:'Acostadillo', label:'Acostadillo', name:'tipo_estructura' },
        { value:'Relleno de cepa', label:'Relleno de cepa', name:'tipo_estructura' },
        { value:'Relleno', label:'Relleno', name:'tipo_estructura' },
        { value:'Relleno de zapatas', label:'Relleno de zapatas', name:'tipo_estructura' },
        { value:'Suelo-cemento', label:'Suelo-cemento', name:'tipo_estructura' },
        { value:'Desplante de muro', label:'Desplante de muro', name:'tipo_estructura' },
    ];
    const history = useHistory();

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setIdTipoEstructura(data.tipo_estructura);
            setCompactacion(props.mainObjectData[1].datos_muestra.compactacion_recompactacion);

            console.log(props.mainObjectData[1].datos_muestra.compactacion_recompactacion);
            setDataForm(data);
        }
    },[]);

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        data.compactacion_recompactacion = compactacion;
        console.log(data);
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de estructura</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposEstructura }
                                placeholder="Selecciona ..."
                                value={tiposEstructura.find(op => {
                                    return op.value === idTipoEstructura
                                })}
                                onChange={(event) => {setIdTipoEstructura(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoEstructura }
                            name="tipo_estructura"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_estructura && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Espesor de capa</Label>
                        <input className="form-control"
                               type="text"
                               name="espesor_de_capa"
                               defaultValue={ dataForm?.espesor_de_capa ? dataForm.espesor_de_capa : undefined }
                               ref={ register({ required:true }) }/>
                                { errors.espesor_de_capa && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 6 }>
                    <FormGroup>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio"
                                   value="Compactación"
                                   className="custom-control-input"
                                   id="radioCompactacion"
                                   defaultChecked={compactacion === 'Compactación'}
                                   name="compactacion_recompactacion"
                                   ref={register}
                                   onChange={() => setCompactacion('Compactación')}
                            />
                            <label className="custom-control-label" htmlFor="radioCompactacion">Compactación</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio"
                                   value="Recompactación"
                                   className="custom-control-input"
                                   id="radioRecompactacion"
                                   name="compactacion_recompactacion"
                                   ref={register}
                                   defaultChecked={compactacion === 'Recompactación'}
                                   onChange={() => setCompactacion('Recompactación')}
                            />
                            <label className="custom-control-label" htmlFor="radioRecompactacion">Recompactación</label>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            
        </Form>
    );
};

export default withRouter(Seccion3);