import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {useUsuario} from "../../../context/usuario-context";
import {fetchRecord} from "../../../actions/fetchRecord";
import {withRouter, useHistory, Link} from "react-router-dom";
import {
    faBook,
    faChevronLeft,
    faCloudDownloadAlt,
    faDownload,
    faPlus,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MUIDataTable from "mui-datatables";
import {muiTableOptions} from "../../../constants/muiTableOptions";
import {fetchRecords} from "../../../actions/fetchRecords";
import FiltroFecha from "../filtroFecha";
import ModalNuevoReporte from "../modals/modalNuevoReporte";
import ModalDescargaMasiva from "../modals/modalDescargaMasiva";
import StyledButton from "../button";
import ModalDescargaReporte from "../modals/modalDescargaReporte";
import ModalImprimirReporte from "../modals/modalImprimirReporte";
const Reportes = (props) => {
    const { setBreadcrumbItems } = useUsuario();
    const history = useHistory();
    const idMuestra  = props.match.params.idMuestra;
    const tipoMuestra  = props.match.params.tipoMuestra;
    const [records,setRecords] = useState([]);
    const [modalControl,setModalControl] = useState(false);
    const [modalDescarga,setModalDescarga] = useState(false);
    const [modalDescargaReporte,setModalDescargaReporte] = useState(false);
    const [modalImprimirReporte,setModalImprimirReporte] = useState(false);
    const [selectedRecord,setSelectedRecord] = useState(null);

    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':`/capturista/muestras/${tipoMuestra}`,
                'item':`Muestras ${tipoMuestra}`
            },
            {
                'item':`Reportes muestra ${idMuestra}`
            }
        ]);

        getRecords();
        if(idMuestra) {
            getRecords();
        }
    },[]);

    const toggleModalControl = () => {
        setModalControl(!modalControl);
    }

    const toggleModalDescarga = () => {
        setModalDescarga(!modalDescarga);
    }

    const getRecords = async () => {
        try {
            const result = await fetchRecords(`reportes/${idMuestra}`);
            if(result.done) {
                setRecords(result.done);
            }
        }catch (e) {
            console.log(e);
        }
    };

    const columns = [{
        name: "numero_informe",
        label: "Número de Informe",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "fechaHora",
        label: "Fecha de creación",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "capturista",
        label: "Creado por",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "id",
        label: " ",
        options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div className="d-flex justify-content-center">
                        <StyledButton onClick={() => {
                            setSelectedRecord(tableMeta.rowData[3]);
                            setModalImprimirReporte(!modalImprimirReporte);
                        }} className="small basic mr-2"><FontAwesomeIcon icon={faPrint} className="mr-1"/>Imprimir</StyledButton>
                        <StyledButton onClick={() => {
                            setSelectedRecord(tableMeta.rowData[3]);
                            setModalDescargaReporte(!modalDescargaReporte);
                        }} className="small basic mr-2"><FontAwesomeIcon icon={faCloudDownloadAlt} className="mr-1"/>Descargar</StyledButton>
                    </div>
                );
            }
        }
    }];

    return(
        <div>
            <ModalNuevoReporte modalControl={modalControl} toggleModalControl={toggleModalControl} idMuestra={idMuestra}/>
            <ModalDescargaMasiva modalControl={modalDescarga} toggleModalControl={toggleModalDescarga} idMuestra={idMuestra}/>
            <ModalDescargaReporte modalControl={modalDescargaReporte} toggleModalControl={setModalDescargaReporte} idReporte={selectedRecord} tipoMuestra={tipoMuestra} />
            <ModalImprimirReporte modalControl={modalImprimirReporte} toggleModalControl={setModalImprimirReporte} idReporte={selectedRecord} tipoMuestra={tipoMuestra}/>
            <Row className="text-center">
                <Col>
                    <h3>Reportes de la Muestra { idMuestra }</h3>
                </Col>
            </Row>
            <Row className="justify-content-around mt-5">
                <Col sm={6} className="text-left">
                    <FiltroFecha
                        setRecords={setRecords}
                        url={`reportes/${idMuestra}`}
                    />
                </Col>
                <Col sm={3} className="text-right">
                    <Button className="primary" onClick={toggleModalDescarga}>
                        <FontAwesomeIcon icon={faDownload} className="mr-1"/>Descarga Masiva
                    </Button>
                </Col>
                <Col sm={2} className="text-right">
                    <Button className="primary" onClick={toggleModalControl}>
                        <FontAwesomeIcon icon={faPlus} className="mr-1"/>Generar reporte
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MUIDataTable
                        title={'Reportes'}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col className="text-center">
                    <StyledButton className="gray" onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faChevronLeft}/>   Volver
                    </StyledButton>
                </Col>
            </Row>

        </div>
    )
};

export default withRouter(Reportes);
