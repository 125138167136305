import React from "react";
import TablaMuestrasConcreto from "../common/tablaMuestrasConcreto";

const url = 'muestras-tipo/Vigas';
const tituloTabla = 'Muestras Flexión';
const tipoMuestra = 'Vigas';

const MuestrasVigas = () => (
    <TablaMuestrasConcreto
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasVigas;