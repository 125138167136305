import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from "./routes/routes";
import {UsuarioProvider} from "./context/usuario-context";
import './styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Worker } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

ReactDOM.render(
    <Worker
        workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js"
    >
        <BrowserRouter>
            <UsuarioProvider>
                <AppRoutes/>
            </UsuarioProvider>
        </BrowserRouter>
    </Worker>
    ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
