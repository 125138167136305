import React, {useState,useEffect, useMemo} from 'react';
import {daylightBlue} from "../constants/themes";

const UsuarioContext = React.createContext();

export function UsuarioProvider (props) {
    const [usuario,setUsuario] = useState(null); //user: Object { id: 8, email: "mail@example.com", tipo_usuario: 1, … }
    const [tipoUsuario,setTipoUsuario] = useState(null);
    const [cargandoUsuario,setCargandoUsuario] = useState(false);
    const [userLoggedIn,setUserLoggedIn] = useState(null);
    const [filterBar,setFilterBar] = useState(null);
    const [tema,setTema] = useState(daylightBlue);
    const [fondo,setFondo] = useState('white');
    const [fechaInicioFiltro,setFechaInicioFiltro] = useState(null);
    const [fechaFinFiltro,setFechaFinFiltro] = useState(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        return function cleanup() {
            abortController.abort();
        }
    },[]);

    useEffect(() => {
        
    },[]);

    const value = useMemo(() => {
        return ({
            usuario,
            cargandoUsuario,
            setUsuario,
            setTipoUsuario,
            tipoUsuario,
            userLoggedIn,
            setCargandoUsuario,
            setUserLoggedIn,
            setTema,
            tema,
            fondo,
            setFondo,
            setFilterBar,
            filterBar,
            fechaInicioFiltro,
            setFechaInicioFiltro,
            fechaFinFiltro,
            setFechaFinFiltro,
            breadcrumbItems,
            setBreadcrumbItems
        });
    },[fechaInicioFiltro,setBreadcrumbItems,breadcrumbItems, setFechaInicioFiltro, fechaFinFiltro, setFechaFinFiltro,setFilterBar, filterBar,usuario,fondo,tema,userLoggedIn,cargandoUsuario,tipoUsuario,setTipoUsuario,setCargandoUsuario]);

    return <UsuarioContext.Provider value={value} {...props}/>
}

export function useUsuario() {
    const context = React.useContext(UsuarioContext);
    if(!context) {
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}