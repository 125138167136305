import React, {useEffect, useState} from "react";
import { fetchRecord } from "../../../../actions/fetchRecord";
import { useUsuario } from "../../../../context/usuario-context";
import DetallesCompactacion from "./compactacion";
import './styles.scss';

const DetallesMuestra = props => {
    const { setBreadcrumbItems } = useUsuario();
    const idRegistro  = props.match.params.idRegistro;
    const [datosMuestra, setDatosMuestra] = useState(null);
    const [tipoMuestra, setTipoMuestra] = useState(null);

    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/muestras',
                'item':'Muestras'
            },
            {
                'item':'Detalles de la muestra'
            }
        ]);

        if(idRegistro) {
            getRecord();
        }
    },[]);

    async function getRecord() {
        try {
            const result = await fetchRecord(idRegistro,'muestras');
            if(result) {
                setDatosMuestra(result.done);
                setTipoMuestra(result.done.muestra.tipo_muestra);
            }
        }catch (e) {
            console.log(e);
        }
    }

    return(
        <div>
            { tipoMuestra === 'Compactación' && <DetallesCompactacion
                datosMuestra={datosMuestra}
            /> }
        </div>
    )
};

export default DetallesMuestra;