import React from "react";
import Ensayes from "../index";

const url = 'ensayes-tipo/Calidad';
const tituloTabla = 'Ensayes pendientes de Calidad';
const tipoMuestra = 'Calidad';

const EnsayesCalidad = () => (
    <Ensayes
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default EnsayesCalidad;