import React, {useEffect, useState} from "react";
import Seccion1 from "./seccion1";
import Seccion2 from "./seccion2";
import Seccion3 from "./seccion3";
import {fetchRecord} from "../../../../../actions/fetchRecord";

const Acero = ( props ) => {
    const [idUltimaMuestra,setIdUltimaMuestra] = useState(null);

    useEffect(() => {
        async function getUltimaMuestra() {
            const result = await fetchRecord(null,'muestras/ultima');
            if(result){
                setIdUltimaMuestra(result.id);
            }
        };

        getUltimaMuestra();
    },[]);

    return(
        <div>
            <p className="divider animate fadeInRight">
                Datos de la <b> Muestra { props.idRegistro ? props.idRegistro : idUltimaMuestra + 1 } </b> de tipo <b>Acero</b>  -  Paso { props.seccion } de 3
            </p>
            <hr/>
            { props.seccion === 1 ? <Seccion1 setSeccion={ props.setSeccion }
                                              childFormData={ props.childFormData }
                                              setChildFormData={ props.setChildFormData }
                                              mainObjectData={ props.mainObjectData }
                                              setMainObjectData={ props.setMainObjectData }
                                              idRegistro={ props.idRegistro }
            /> : '' }
            { props.seccion === 2 ? <Seccion2 setSeccion={ props.setSeccion }
                                              childFormData={ props.childFormData }
                                              setChildFormData={ props.setChildFormData }
                                              mainObjectData={ props.mainObjectData }
                                              setMainObjectData={ props.setMainObjectData }
                                              idRegistro={ props.idRegistro }
            /> : '' }
            { props.seccion === 3 ? <Seccion3 setSeccion={ props.setSeccion }
                                              childFormData={ props.childFormData }
                                              setChildFormData={ props.setChildFormData }
                                              mainObjectData={ props.mainObjectData }
                                              setMainObjectData={ props.setMainObjectData }
                                              idRegistro={ props.idRegistro }
            /> : '' }
        </div>
    )
};

export default Acero;