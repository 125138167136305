import {url_base} from "./../constants/api_url";
import axios from 'axios';
import CookieService from "../services/CookieService";

const api_url = url_base;

export const fetchRecord = async (idRecord,resource,type = null) => {

    const authToken = CookieService.get('access_token');

    let url = `${api_url}${resource}`;

    if(idRecord) {
        url += `/${idRecord}`;
    }

    const options = {
        url:url,
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
        },
    };

    if(type) {
        options.responseType = type
    };

    try{
        const response = await axios(options);

        if(response) {
            return response.data;
        }
    }catch (e) {
        console.log(e);
    }
};

