import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import {useHistory} from "react-router-dom";

const Seccion2 = props => { 
    const { register,handleSubmit,errors } = useForm();
    const [dataForm,setDataForm] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);

        }
    },[]);

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };
    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>* Peso seco más cápsula</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_seco_capsula"
                               defaultValue={ dataForm ? dataForm.peso_seco_capsula : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.inclinacion_corruga && <small>Ingresa un valor</small> }


                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tara</Label>
                        <input className="form-control"
                               type="text"
                               name="tara"
                               defaultValue={ dataForm?.tara ? dataForm.tara : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.tara && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Etapa</Label>
                        <input className="form-control"
                               type="text"
                               name="etapa"
                               defaultValue={ dataForm ? dataForm.etapa : undefined }
                               ref={ register }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso suelo húmedo más molde</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_suelo_humedo_molde"
                               defaultValue={ dataForm?.peso_suelo_humedo_molde ? dataForm.peso_suelo_humedo_molde : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.peso_suelo_humedo_molde && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Contenido de agua</Label>
                        <input className="form-control"
                               type="text"
                               name="contenido_agua"
                               defaultValue={ dataForm ? dataForm.contenido_agua : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.contenido_agua && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso tara</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_tara"
                               defaultValue={ dataForm?.peso_tara ? dataForm.peso_tara : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.peso_tara && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso agua</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_agua"
                               defaultValue={  dataForm?.peso_agua ?  dataForm?.peso_agua : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.peso_agua && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso suelo húmedo</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_suelo_humedo"
                               defaultValue={  dataForm?.peso_suelo_humedo ?  dataForm?.peso_suelo_humedo : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.peso_suelo_humedo && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso volumétrico suelo</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_volumetrico_suelo"
                               defaultValue={  dataForm?.peso_volumetrico_suelo ?  dataForm?.peso_volumetrico_suelo : undefined }
                               ref={ register({ required:true }) }/>
                               { errors.peso_volumetrico_suelo && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso húmedo más capsula</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_humedo_capsula"
                               defaultValue={  dataForm?.peso_humedo_capsula ?  dataForm?.peso_humedo_capsula : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_humedo_capsula && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso suelo seco 1</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_suelo_seco_1"
                               defaultValue={  dataForm?.peso_suelo_seco_1 ?  dataForm?.peso_suelo_seco_1 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_suelo_seco_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso suelo seco 2</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_suelo_seco_2"
                               defaultValue={  dataForm?.peso_suelo_seco_2 ?  dataForm?.peso_suelo_seco_2 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_suelo_seco_2 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Notas</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="notas"
                                  defaultValue={ dataForm?.notas ?  dataForm?.notas : undefined }
                                  ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion2;