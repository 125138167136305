import React, { useState} from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { withRouter} from "react-router-dom";
import './styles.scss';
import classnames from 'classnames';
import {Menu} from "../common/menu";
import MuestrasCompresion from "./compresion";
import MuestrasVigas from "./vigas";

const MuestrasConcreto = ( ) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    };

    return(
        <div>
            <Row className="mt-4 text-center">
                <Col>
                    <h4 className="d-lg-none mt-5">Muestras Concreto</h4>
                    <h3 className="d-none d-lg-inline mt-5">Muestras Concreto</h3>
                </Col>
            </Row>
            <Menu activeItem={'Concreto'}/>
            <Nav tabs className="mt-4">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Compresión
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        Flexión
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <MuestrasCompresion/>
                </TabPane>
                <TabPane tabId="2">
                    <MuestrasVigas/>
                </TabPane>
            </TabContent>
        </div>
    )
};

export default withRouter(MuestrasConcreto);