import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";

const Seccion2 = props => { 
    const { register,handleSubmit,errors } = useForm();
    const [dataForm,setDataForm] = useState(null);



    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);

        }
    },[]);
    
    const onSubmit = (data) => { 
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(3);
     };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Lote</Label>
                        <input className="form-control"
                               type="text"
                               name="lote"
                               defaultValue={ dataForm ? dataForm.lote : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Nivel</Label>
                        <input className="form-control"
                               type="text"
                               name="nivel"
                               defaultValue={ dataForm?.nivel ? dataForm.nivel : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Etapa</Label>
                        <input className="form-control"
                               type="text"
                               name="etapa"
                               defaultValue={ dataForm ? dataForm.etapa : undefined }
                               ref={ register }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Paquete</Label>
                        <input className="form-control"
                               type="text"
                               name="paquete"
                               defaultValue={ dataForm?.paquete ? dataForm.paquete : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Fracción</Label>
                        <input className="form-control"
                               type="text"
                               name="fraccion"
                               defaultValue={ dataForm?.fraccion ? dataForm.fraccion : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Ubicación</Label>
                        <input className="form-control"
                               type="text"
                               name="ubicacion"
                               defaultValue={ dataForm?.ubicacion ? dataForm.ubicacion : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Manzana</Label>
                        <input className="form-control"
                               type="text"
                               name="manzana"
                               defaultValue={  dataForm?.manzana ?  dataForm?.manzana : undefined }
                               ref={ register }/>

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Número de capa</Label>
                        <input className="form-control"
                               type="text"
                               name="numero_capa"
                               defaultValue={  dataForm?.numero_capa ?  dataForm?.numero_capa : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Cadenamiento Inicial</Label>
                        <input className="form-control"
                               type="text"
                               name="cadenamiento_inicial"
                               defaultValue={  dataForm?.cadenamiento_inicial ?  dataForm?.cadenamiento_inicial : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Cadenamiento Final</Label>
                        <input className="form-control"
                               type="text"
                               name="cadenamiento_final"
                               defaultValue={  dataForm?.cadenamiento_final ?  dataForm?.cadenamiento_final : undefined }
                               ref={ register }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Eje</Label>
                        <input className="form-control"
                               type="text"
                               name="eje"
                               defaultValue={  dataForm?.eje ?  dataForm?.eje : undefined }
                               ref={ register }/>

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Unidad</Label>
                        <input className="form-control"
                               type="text"
                               name="unidad"
                               defaultValue={  dataForm?.unidad ?  dataForm?.unidad : undefined }
                               ref={ register }/>

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Notas</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="notas"
                                  defaultValue={ dataForm?.notas ?  dataForm?.notas : undefined }
                                  ref={ register }/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion2;