import React, {useEffect, useState} from "react";
import { Col, Row, FormGroup, Label, Form, Button } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { customStylesSelect } from "../../../../constants/customStylesSelect";
import Compresion from "./compresion";
import moment from 'moment';
import 'moment/locale/es';
import {useHistory, withRouter} from 'react-router-dom';
import {fetchRecord} from "../../../../actions/fetchRecord";
import Acero from "./acero";
import {fetchRecords} from "../../../../actions/fetchRecords";
import {useUsuario} from "../../../../context/usuario-context";
import Vigas from "./vigas";
import Morteros from "./morteros";
import Blocks from "./blocks";
import Compactacion from "./compactacion";
import PesoVolumetrico from "./pesoVolumetrico";
import Calidad from "./calidad";
import Granulometria from "./granulometria";

registerLocale('es', es);

const FormularioMuestras = props => {
    const { setBreadcrumbItems } = useUsuario();
    const { register, handleSubmit, errors } = useForm();
    const { setFilterBar } = useUsuario();

    const [ seccion, setSeccion ] = useState(1);
    const [ fechaMuestra, setFechaMuestra ] = useState(new Date());
    const [ idObra,setIdObra ] = useState(null);
    const [ idTipoMuestra,setIdTipoMuestra ] = useState(null );
    const [ buttonGuardarDisabled,setButtonGuardarDisabled ] = useState(true);
    const [ selectObras, setSelectObras ] = useState([] );

    const history = useHistory();
    const idRegistro  = props.match.params.idRegistro;
    const tipoMuestra  = props.match.params.tipoMuestra;

    const [mainObjectData,setMainObjectData] = useState([
        "created",
        {
            "muestra": {

            },
            "datos_muestra": {

            },
            "ensayes": [

            ]
        }
    ]);

    const tiposMuestra = [ 
        { value:'Acero',label:'Acero ',name:'tipo_muestra' },
        { value:'Blocks',label:'Blocks ',name:'tipo_muestra' },
        { value:'Calidad',label:'Calidad ',name:'tipo_muestra' },
        { value:'Compactación',label:'Compactación ',name:'tipo_muestra' },
        { value:'Compresión',label:'Compresión ',name:'tipo_muestra' },
        { value:'Granulometría',label:'Granulometría ',name:'tipo_muestra' },
        { value:'Morteros',label:'Morteros ',name:'tipo_muestra' },
        { value:'Peso Volumétrico Seco Máximo',label:'Peso Volumétrico Seco Máximo ',name:'tipo_muestra' },
        { value:'Vigas',label:'Flexión',name:'tipo_muestra' },
     ];

    const onSubmit = async (data) => {

        data.fecha_de_la_muestra = moment(fechaMuestra).format('YYYY-MM-DD');
        data.tipo_muestra = idTipoMuestra;
        Object.assign(mainObjectData[ 1 ].muestra,data);
    };

    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/muestras',
                'item':'Muestras'
            },
            {
                'item':(idRegistro ? 'Editar' : 'Agregar') + ' Muestra'
            }
        ]);

        if(tipoMuestra) {
            setIdTipoMuestra(tipoMuestra);
        }

        async function getRecord() {
            try {
                const result = await fetchRecord(idRegistro,'muestras');
                if(result) {

                    setIdObra(result.done.muestra.obra_id);
                    const objMain = mainObjectData;
                    setFechaMuestra(new Date(result.done.muestra.fecha_de_la_muestra));
                    Object.assign(objMain[ 1 ].datos_muestra,result.done.datos_muestra);
                    Object.assign(objMain[ 1 ].ensayes,result.done.ensayes);
                    setMainObjectData(objMain);
                    setIdTipoMuestra(result.done.muestra.tipo_muestra);
                    setButtonGuardarDisabled(false);

                }
            }catch (e) {
                console.log(e);
            }
        }

        async function getObras() {
            try {
                const result = await fetchRecords('obras');

                if(result.done) {

                    const arrObras = [];

                    result.done.map((val) => {
                        arrObras.push({value:val.id,label:val.nombre_obra,name:'obra_id'})
                    });

                    setSelectObras(arrObras);

                }
            }catch (e) {
                console.log(e);
            }
        }
        
        if(idRegistro) {
            getRecord();
        }

        setFilterBar(false);

        getObras();
    },[  ]);

    /**
     * Al seleccionar un idTipoMuestra se hace submit del formulario muestra
     */
    useEffect(() => {
        if(idObra && idTipoMuestra) {
            handleSubmit(onSubmit)();
        }
    },[ idTipoMuestra, idObra ]);

    const handleBackButton = () => {
        let varSeccion = seccion;

        varSeccion = varSeccion - 1;

        if(varSeccion === 0) {
            history.push(`/capturista/muestras${tipoMuestra ? '/' + tipoMuestra : ''}`);
        }

        setSeccion( varSeccion );

    };

    return(
        <div>
            <Row className="text-center">
                <Col>
                    <h3>{ idRegistro ? 'Editar' : 'Agregar'} muestra { idRegistro ? idRegistro : ''}</h3>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col sm={ 8 }>
                    <Form id="form-muestra" onSubmit={ handleSubmit(onSubmit) } >
                            <Row>
                                <Col sm={ 4 }>
                                    <FormGroup>
                                        <Label>* Fecha de la muestra</Label>
                                        <br/>
                                        <DatePicker
                                            locale="es"
                                            className="form-control"
                                            name="fecha"
                                            dateFormat="dd/MMMM/yyyy"
                                            selected={ fechaMuestra }
                                            onChange={ date => setFechaMuestra(date) } />
                                    </FormGroup>
                                </Col>
                                <Col sm={ 6 }>

                                    <FormGroup>
                                        <Label>* Tipo de muestra</Label>
                                        <Select styles={ customStylesSelect }
                                                options={ tiposMuestra }
                                                placeholder="Selecciona ..."
                                                value={ tiposMuestra.find(op => {
                                                    return op.value === idTipoMuestra
                                                }) }
                                                onChange={ (event) => {
                                                    setIdTipoMuestra(event.value);
                                                    setButtonGuardarDisabled(false);
                                                } }
                                        />
                                        <input
                                            tabIndex={ -1 }
                                            autoComplete="off"
                                            style={ { opacity: 0, height: 0, display: 'none' } }
                                            value={ idTipoMuestra }
                                            name="tipo_muestra"
                                            ref={ register({ required:true }) }
                                        />
                                        { errors.tipo_muestra && <small>Ingresa un valor</small> }
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                { idTipoMuestra ? <Col sm={ 4 } className="animate one fadeIn">
                                    <FormGroup>
                                        <Label>* Obra</Label>
                                        <Select styles={ customStylesSelect }
                                                options={ selectObras }
                                                placeholder="Selecciona ..."
                                                value={ selectObras.find(op => {
                                                    return op.value === idObra
                                                }) }
                                                onChange={ (event) => {
                                                    setIdObra(event.value);
                                                    if(idTipoMuestra) {
                                                        setButtonGuardarDisabled(false);
                                                    }
                                                } }
                                        />
                                        <input
                                            tabIndex={ -1 }
                                            autoComplete="off"
                                            style={ { opacity: 0, height: 0, display: 'none' } }
                                            value={ idObra }
                                            name="obra_id"
                                            ref={ register({ required:true }) }
                                        />
                                        { errors.obra_id && <small>Ingresa un valor</small> }
                                    </FormGroup>
                                </Col> : '' }

                            </Row>

                    </Form>

                    { idTipoMuestra === 'Acero' && idObra &&
                    <Acero
                        mainObjectData={ mainObjectData }
                        setMainObjectData={ setMainObjectData }
                        seccion={ seccion }
                        setSeccion={ setSeccion }
                        idRegistro={ idRegistro }
                    /> }

                    { idTipoMuestra === 'Blocks' && idObra &&
                    <Blocks
                        mainObjectData={ mainObjectData }
                        setMainObjectData={ setMainObjectData }
                        seccion={ seccion }
                        setSeccion={ setSeccion }
                        idRegistro={ idRegistro }
                    /> }

                    { idTipoMuestra === 'Calidad' && idObra &&
                        <Calidad
                            mainObjectData={ mainObjectData }
                            setMainObjectData={ setMainObjectData }
                            seccion={ seccion }
                            setSeccion={ setSeccion }
                            idRegistro={ idRegistro }
                         /> }

                    { idTipoMuestra === 'Compactación' && idObra &&
                        <Compactacion
                            mainObjectData={ mainObjectData }
                            setMainObjectData={ setMainObjectData }
                            seccion={ seccion }
                            setSeccion={ setSeccion }
                            idRegistro={ idRegistro }
                         /> }

                    { idTipoMuestra === 'Compresión' && idObra &&
                        <Compresion
                            mainObjectData={ mainObjectData }
                            setMainObjectData={ setMainObjectData }
                            seccion={ seccion }
                            setSeccion={ setSeccion }
                            idRegistro={ idRegistro }
                         /> }

                    { idTipoMuestra === 'Granulometría' && idObra &&
                        <Granulometria
                            mainObjectData={ mainObjectData }
                            setMainObjectData={ setMainObjectData }
                            seccion={ seccion }
                            setSeccion={ setSeccion }
                            idRegistro={ idRegistro }
                         /> }

                    { idTipoMuestra === 'Morteros' && idObra &&
                    <Morteros
                        mainObjectData={ mainObjectData }
                        setMainObjectData={ setMainObjectData }
                        seccion={ seccion }
                        setSeccion={ setSeccion }
                        idRegistro={ idRegistro }
                    /> }

                    { idTipoMuestra === 'Peso Volumétrico Seco Máximo' && idObra &&
                    <PesoVolumetrico
                        mainObjectData={ mainObjectData }
                        setMainObjectData={ setMainObjectData }
                        seccion={ seccion }
                        setSeccion={ setSeccion }
                        idRegistro={ idRegistro }
                    /> }

                    { idTipoMuestra === 'Vigas' && idObra &&
                    <Vigas
                        mainObjectData={ mainObjectData }
                        setMainObjectData={ setMainObjectData }
                        seccion={ seccion }
                        setSeccion={ setSeccion }
                        idRegistro={ idRegistro }
                    /> }



                    <p className="mt-4 text-center">Los campos con * son obligatorios</p>
                    <Row className="mt-4 justify-content-between">
                        <Col sm={ 4 } className="text-right">
                            <Button onClick={() => handleBackButton() } className="secondary">
                                { seccion === 1 ? 'Cancelar' : 'Volver' }
                            </Button>
                        </Col>
                        <Col sm={ 4 } className="text-left">
                            <Button type="submit" form="form-child-muestra" className="primary" disabled={ buttonGuardarDisabled }>
                                Guardar y continuar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
 };

export default withRouter(FormularioMuestras);