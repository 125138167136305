import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [idTipoCemento,setIdTipoCemento] = useState(undefined);
    const [idTipoFormaMuestra,setIdTipoFormaMuestra] = useState(undefined);
    const [idTipoAditivo,setIdTipoAditivo] = useState(undefined);
    const [idTipoColocacion,setIdTipoColocacion] = useState(undefined);
    const [idTipoMezclado,setIdTipoMezclado] = useState(undefined);
    const [idTipoConcreto,setIdTipoConcreto] = useState(undefined);
    const [idTipoEdadRuptura,setIdTipoEdadRuptura] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const tiposCemento = [
        { value:'CPC30',label:'CPC30',name:'tipo_cemento' },
        { value:'CPC40',label:'CPC40',name:'tipo_cemento' },
        { value:'CPC30R',label:'CPC30R',name:'tipo_cemento' },
        { value:'CPC40R',label:'CPC40R',name:'tipo_cemento' },
        { value:'Otro',label:'Otro',name:'tipo_cemento' },
    ];

    const tiposFormaMuestra = [
        { value:'Cilindro',label:'Cilindro',name:'forma_muestra' },
        { value:'Cubo',label:'Cubo',name:'forma_muestra' },
    ];
    
    const tiposAditivo = [
        {  value:'De linea',label:'De linea',name:'aditivo'  },
        { value:'Fluidizante',label:'Fluidizante',name:'aditivo' },
        { value:'Super fluidizante',label:'Super fluidizante',name:'aditivo' },
        { value:'Con fibra',label:'Con fibra',name:'aditivo' },
        { value:'De alto rango',label:'De alto rango',name:'aditivo' },
        { value:'Otro',label:'Otro',name:'aditivo' },
        { value:'Sin aditivo',label:'Sin aditivo',name:'aditivo' },
    ];

    const tiposColocacion = [
        { value:'Artesa',label:'Artesa',name:'colocacion' },
        { value:'Canalon',label:'Canalon',name:'colocacion' },
        { value:'Bomba',label:'Bomba',name:'colocacion' },
        { value:'Botes',label:'Botes',name:'colocacion' },
        { value:'Otros',label:'Otros',name:'colocacion' },
    ];

    const tiposMezclado = [
        { value:'Revolvedora',label:'Revolvedora',name:'mezclado' },
        { value:'Olla',label:'Olla',name:'mezclado' },
        { value:'Manual',label:'Manual',name:'mezclado' },
        { value:'Mixer',label:'Mixer',name:'mezclado' },
    ];

    const tiposConcreto = [
        { value:'Hecho en obra',label:'Hecho en obra',name:'tipo_concreto' },
        { value:'Premezclado',label:'Premezclado',name:'tipo_concreto' },
        { value:'Lanzado',label:'Lanzado',name:'tipo_concreto' },
        { value:'Especial',label:'Especial',name:'tipo_concreto' },
    ];

    const tiposEdadRuptura = [
        { value:'1',label:'1',name:'edad_ruptura' },
        { value:'3',label:'3',name:'edad_ruptura' },
        { value:'7',label:'7',name:'edad_ruptura' },
        { value:'14',label:'14',name:'edad_ruptura' },
        { value:'28',label:'28',name:'edad_ruptura' },
        { value:'56',label:'56',name:'edad_ruptura' },
    ];
    
    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
            setIdTipoCemento(data.tipo_cemento);
            setIdTipoFormaMuestra(data.forma_muestra);
            setIdTipoColocacion(data.colocacion);
            setIdTipoAditivo(data.aditivo);
            setIdTipoMezclado(data.mezclado);
            setIdTipoConcreto(data.tipo_concreto);
            setIdTipoEdadRuptura(data.edad_ruptura);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tamaño máximo agregado</Label>
                        <input className="form-control" type="text" name="tamano_max_agregado" 
                               defaultValue={ dataForm ? dataForm.tamano_max_agregado : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.tamano_max_agregado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Revenimiento de proyecto</Label>
                        <input className="form-control" type="text" name="revenimiento_1" 
                               defaultValue={ dataForm ? dataForm.revenimiento_1 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.revenimiento_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de cemento</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposCemento }
                                placeholder="Selecciona ..."
                                value={tiposCemento.find(op => {
                                    return op.value === idTipoCemento
                                })}
                                onChange={(event) => {setIdTipoCemento(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoCemento }
                            name="tipo_cemento"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_cemento && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Forma de la muestra</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposFormaMuestra }
                                placeholder="Selecciona ..."
                                value={tiposFormaMuestra.find(op => {
                                    return op.value === idTipoFormaMuestra
                                })}
                                onChange={(event) => {setIdTipoFormaMuestra(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoFormaMuestra }
                            name="forma_muestra"
                            ref={ register({ required:true }) }
                        />
                        { errors.forma_muestra && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Resistencia del proyecto</Label>
                        <input className="form-control"
                               type="text"
                               name="resistencia"
                               defaultValue={ dataForm ? dataForm.resistencia : undefined }
                               ref={register({required:true})}/>
                        {errors.resistencia && <small>Ingresa un valor</small>}
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Aditivo</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposAditivo }
                                placeholder="Selecciona ..."
                                value={tiposAditivo.find(op => {
                                    return op.value === idTipoAditivo
                                })}
                                onChange={(event) => {setIdTipoAditivo(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoAditivo }
                            name="aditivo"
                            ref={ register({ required:true }) }
                        />
                        { errors.aditivo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Colocación</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposColocacion }
                                placeholder="Selecciona ..."
                                value={tiposColocacion.find(op => {
                                    return op.value === idTipoColocacion
                                })}
                                onChange={(event) => {setIdTipoColocacion(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoColocacion }
                            name="colocacion"
                            ref={ register({ required:true }) }
                        />
                        { errors.colocacion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Mezclado</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposMezclado }
                                placeholder="Selecciona ..."
                                value={tiposMezclado.find(op => {
                                    return op.value === idTipoMezclado
                                })}
                                onChange={(event) => {setIdTipoMezclado(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoMezclado }
                            name="mezclado"
                            ref={ register({ required:true }) }
                        />
                        { errors.mezclado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de concreto</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposConcreto }
                                placeholder="Selecciona ..."
                                value={tiposConcreto.find(op => {
                                    return op.value === idTipoConcreto
                                })}
                                onChange={(event) => {setIdTipoConcreto(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none'  } }
                            value={ idTipoConcreto }
                            name="tipo_concreto"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_concreto && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Edad de ruptura</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposEdadRuptura }
                                placeholder="Selecciona ..."
                                value={tiposEdadRuptura.find(op => {
                                    return op.value === idTipoEdadRuptura
                                })}
                                onChange={(event) => {setIdTipoEdadRuptura(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoEdadRuptura }
                            name="edad_ruptura"
                            ref={ register({ required:true }) }
                        />
                        { errors.edad_ruptura && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* No. de remisión</Label>
                        <input className="form-control" type="text" name="numero_remision" 
                               defaultValue={ dataForm ? dataForm.numero_remision : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.numero_remision && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* No. unidad de entrega</Label>
                        <input className="form-control" type="text" name="numero_unidad_entrega" 
                               defaultValue={ dataForm ? dataForm.numero_unidad_entrega : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.numero_unidad_entrega && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>

        </Form>
    );
 };

export default Seccion1;