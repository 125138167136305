import React, {useEffect, useState} from "react";
import {Row, Col, Button } from "reactstrap";
import MUIDataTable from "mui-datatables";
import {muiTableOptions} from "../../../../../../constants/muiTableOptions";
import {fetchRecords} from "../../../../../../actions/fetchRecords";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory } from "react-router-dom";
import FiltroFecha from "../../../../../common/filtroFecha";
import {deleteRecord} from "../../../../../../actions/deleteRecord";
import ModalEliminarRegistro from "../../../../../common/modals/modalEliminarRegistro";

const TablaMuestrasConcreto = props => {
    const [records,setRecords] = useState([]);
    const url = props.url ? props.url : 'muestras';
    const urlAgregar = props.tipoMuestra ? '/capturista/muestras/agregar/' + props.tipoMuestra : '/capturista/muestras/agregar';
    const tituloTabla = props.tituloTabla ? props.tituloTabla : 'Ensayes pendientes';
    const [modalEliminar,setModalEliminar] = useState(false);
    const [tituloModal,setTituloModal] = useState(false);
    const [selectedRecordId,setSelectedRecordId] = useState(null);
    const history = useHistory();

    useEffect(() => {
        getRecords();
    },[]);

    const getRecords = async () => {
        try {

            const result = await fetchRecords(url);

            if(result) {
                setRecords(result.done);
            }


        }catch (e) {
            console.log(e);
        }
    };

    const eliminarRegistro = async () => {
        try {

            setModalEliminar(false);

            await deleteRecord(selectedRecordId,'muestras');

            await getRecords();

        }catch (e) {
            console.log(e);
        }
    };

    const columns = [{
        name: "muestra",
        label: "Muestra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "obra",
        label: "Obra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "fecha_de_muestreo",
        label: "Fecha de muestreo",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "tipo_muestra",
        label: "Tipo",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "cumple",
        label: "Cumple",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "id",
        label: "Acciones",
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                        <Button type="Button" title="Editar" onClick={() => {

                            //Redirect al formulario de edición
                            history.push(`/capturista/muestras/editar/${tableMeta.rowData[0]}`);

                        }} className="mr-2 btnAction"><FontAwesomeIcon icon={faEdit}/>
                        </Button>

                        <Button type="Button" title="Eliminar" onClick={() => {
                            setSelectedRecordId(tableMeta.rowData[0]);
                            setModalEliminar(!modalEliminar);
                            setTituloModal('muestra ' + tableMeta.rowData[0]);
                        }} className="mr-2 btnAction"><FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                );
            }
        }
    }];

    return(

        <div>
            {modalEliminar ? <ModalEliminarRegistro
                toggleDeleteModal={() => setModalEliminar(!modalEliminar)}
                deleteModal={modalEliminar}
                titulo={tituloModal}
                deleteRegister={() => eliminarRegistro()}
            /> : ''
            }
            <Row className="justify-content-around mt-4">
                <Col sm={8} className="text-left">
                    <FiltroFecha
                        setRecords={setRecords}
                        url={url}
                    />
                </Col>
                <Col sm={3} className="text-right">
                    <Link to={urlAgregar}>
                        <Button className="primary">
                            Agregar muestra
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MUIDataTable
                        title={tituloTabla}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default TablaMuestrasConcreto;