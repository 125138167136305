export const customStylesSelect = {

    control: () => ({
        borderRadius: 10,
        border: '1px solid #979797 !important',
        position: 'relative',
        justifyContent: 'space-between',
        display: '-webkit-flex'
    }),
    dropdownIndicator: () => ({
        color: 'black',
        marginRight: 10
    }),
    indicatorSeparator: () => ({
        border: 'none',
    }),
    menu: () => ({
         zIndex: 100000,
        marginTop: 4,
        overflow: 'hidden',
        border: '1px solid #979797',
        borderRadius: '20px'
    })
};
