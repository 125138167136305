import React, {useEffect, useState} from "react";
import { InputGroup,InputGroupAddon } from "reactstrap";
import './styles.scss';
import DatePicker from "react-datepicker";
import {fetchRecords} from "../../../actions/fetchRecords";
import moment from "moment";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FiltroFecha = props => {
    const [fechaInicio, setfechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);

    useEffect(() => {
        getRecordsFilter();
    },[fechaInicio,fechaFin]);

    async function getRecordsFilter() {

        if(fechaInicio && fechaFin) {
            const result = await fetchRecords(
                `${props.url}/${moment(fechaInicio).format('YYYY-MM-DD')}/${moment(fechaFin).format('YYYY-MM-DD')}`
            );
            if(result) {
                props.setRecords(result.done);
            }
        }else{
            const result = await fetchRecords(
                `${props.url}`
            );
            if(result) {
                props.setRecords(result.done);
            }
        }

    }

    return (
    <div>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
                <span className="input-group-text"><FontAwesomeIcon icon={faCalendar} /></span>
            </InputGroupAddon>
            <label>
                <DatePicker
                    locale="es"
                    className="form-control-group"
                    name="fecha"
                    dateFormat="dd/MMMM/yyyy"
                    selected={fechaInicio}
                    isClearable
                    onChange={date => {
                        setfechaInicio(date);
                        getRecordsFilter();
                    }}
                    placeholderText='Desde'
                />
            </label>
            <DatePicker
                locale="es"
                className="form-control-group end"
                name="fecha"
                dateFormat="dd/MMMM/yyyy"
                minDate={fechaInicio}
                selected={fechaFin}
                isClearable
                placeholderText='Hasta'
                onChange={date => {
                    setFechaFin(date);
                    getRecordsFilter();
                }}/>
        </InputGroup>
    </div>);
};

export default FiltroFecha;