import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import MUIDataTable from "mui-datatables";
import { muiTableOptions } from "../../../constants/muiTableOptions";
import { Link } from "react-router-dom";
import {fetchRecords} from "../../../actions/fetchRecords";
import ModalEliminarRegistro from "../../common/modals/modalEliminarRegistro";
import { deleteRecord } from "../../../actions/deleteRecord";
import {
    faBook, faChevronLeft, faCloudDownloadAlt,
    faEye,
    faFlask,
    faPencilAlt,
    faPlus, faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter, useHistory } from 'react-router-dom';
import {Menu} from "./common/menu";
import FiltroFecha from "../../common/filtroFecha";
import {useUsuario} from "../../../context/usuario-context";
import StyledButton from "../../common/button";
import {url_base} from "../../../constants/api_url";

const Muestras = props => {
    const url = props.url ? props.url : 'muestras';
    const urlAgregar = props.tipoMuestra ? '/capturista/muestras/agregar/' + props.tipoMuestra : '/capturista/muestras/agregar';
    const tituloTabla = props.tituloTabla ? props.tituloTabla : 'Muestras';
    const tipoMuestra = props.tipoMuestra ? props.tipoMuestra : 'Todas';
    const [records,setRecords] = useState([]);
    const [modalEliminar,setModalEliminar] = useState(false);
    const [tituloModal,setTituloModal] = useState(false);
    const [selectedRecordId,setSelectedRecordId] = useState(null);
    const { setBreadcrumbItems, tema } = useUsuario();

    const history = useHistory();

    useEffect(() => {
        getRecords();
        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'item':`Muestras ${props.tipoMuestra ? props.tipoMuestra : ''}`
            }
        ]);
    },[]);

    const eliminarRegistro = async () => {
        try {

            setModalEliminar(false);

            await deleteRecord(selectedRecordId,url);

            await getRecords();

        }catch (e) {
            console.log(e);
        }
    };

    const getRecords = async () => {
        try {
            const result = await fetchRecords(url);
            if(result.done) {
                setRecords(result.done);
            }
        }catch (e) {
            console.log(e);
        }
    };

    const columns = [{
        name: "muestra",
        label: "Muestra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "obra",
        label: "Obra",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "fecha_de_muestreo",
        label: "Fecha de muestreo",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "tipo_muestra",
        label: "Tipo",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "cumple",
        label: "Cumple",
        options: {
            filter: true,
            sort: true,
        }
    },{
        name: "id",
        label: " ",
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div className="d-flex justify-content-center">
                        <Link to={`/capturista/reportes/${tableMeta.rowData[0]}/${tipoMuestra}`}>
                            <StyledButton className="small basic mr-2"><FontAwesomeIcon icon={faBook} className="mr-1"/>Reportes</StyledButton>
                        </Link>
                        <Link to={`/capturista/ensayes/${tipoMuestra}/${tableMeta.rowData[0]}`}>
                            <StyledButton className="small basic mr-2"><FontAwesomeIcon icon={faFlask} className="mr-1"/>Ensayes</StyledButton>
                        </Link>
                        {tableMeta.rowData[4] === 'X' &&
                        <StyledButton size={'small'} theme={'blue'} type="Button" title="Detalles" onClick={() => {

                            history.push(`/capturista/reportes/${tableMeta.rowData[0]}/${tipoMuestra}`);

                        }} className="mr-2 btnAction"><FontAwesomeIcon icon={faEye}/>
                        </StyledButton>
                        }
                        <StyledButton type="Button" title="Editar" onClick={() => {

                            //Redirect al formulario de edición
                            history.push(`/capturista/muestras/editar/${tableMeta.rowData[0]}`);

                        }} className="mr-2 basic small"><FontAwesomeIcon className="mr-1" icon={faPencilAlt}/>Editar
                        </StyledButton>

                        <StyledButton type="Button" title="Eliminar" onClick={() => {
                            setSelectedRecordId(tableMeta.rowData[0]);
                            setModalEliminar(!modalEliminar);
                            setTituloModal('muestra ' + tableMeta.rowData[0]);
                        }} className="small danger mr-2"><FontAwesomeIcon className="mr-1" icon={faTimes}/>Eliminar
                        </StyledButton>
                    </div>
                );
            }
        }
    }];

    return(
        <div>
            {modalEliminar ? <ModalEliminarRegistro
                toggleDeleteModal={() => setModalEliminar(!modalEliminar)}
                deleteModal={modalEliminar}
                titulo={tituloModal}
                deleteRegister={() => eliminarRegistro()}
            /> : ''
            }
            <Row className="mt-4 text-center">
                <Col>
                    <h4 className="d-lg-none mt-5">{tituloTabla}</h4>
                    <h3 className="d-none d-lg-inline mt-5">{tituloTabla}</h3>
                </Col>
            </Row>
            <Menu activeItem={tipoMuestra}/>
            <Row className="justify-content-around mt-5">
                <Col sm={6} className="text-left">
                    <FiltroFecha
                        setRecords={setRecords}
                        url={url}
                    />
                </Col>
                <Col sm={3} className="text-right">
                    <a href={`${url_base}reportes-descarga/${tipoMuestra}`} download={`reportes-muestra-${tipoMuestra}.pdf`}>
                        <StyledButton className="gray">
                            <FontAwesomeIcon icon={faCloudDownloadAlt} className="mr-1"/>Descargar Reportes
                        </StyledButton>
                    </a>
                </Col>
                <Col sm={3} className="text-right">
                    <Link to={urlAgregar}>
                        <StyledButton tema={tema}>
                            <FontAwesomeIcon icon={faPlus} className="mr-1"/>Agregar muestra
                        </StyledButton>
                    </Link>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MUIDataTable
                        title={tituloTabla}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col className="text-center">
                    <StyledButton className="gray" onClick={() => history.push('/capturista')}>
                        <FontAwesomeIcon icon={faChevronLeft}/>   Volver
                    </StyledButton>
                </Col>
            </Row>
        </div>
    )
};

export default withRouter(Muestras);