import React from "react";
import Muestras from "../index";

const url = 'muestras-tipo/Calidad';
const tituloTabla = 'Muestras Calidades';
const tipoMuestra = 'Calidad';

const MuestrasCalidades = () => (
    <Muestras
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasCalidades;