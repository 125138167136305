import React from "react";
import TablaMuestrasConcreto from "../common/tablaMuestrasConcreto";

const url = 'muestras-tipo/Compresión';
const tituloTabla = 'Muestras Compresión';
const tipoMuestra = 'Compresión';

const MuestrasCompresion = () => (
    <TablaMuestrasConcreto
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasCompresion;