import React from "react";
import Muestras from "../index";

const url = 'muestras-tipo/Compactación';
const tituloTabla = 'Muestras Compactación';
const tipoMuestra = 'Compactación';

const MuestrasCompactacion = () => (
    <Muestras
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default MuestrasCompactacion;