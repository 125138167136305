import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {customStylesSelect} from "../../../../../../constants/customStylesSelect";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import { withRouter, useHistory } from 'react-router-dom';

const Cilindro = ( props ) => {
    const {  register, handleSubmit, errors  } = useForm();
    const [dataForm,setDataForm] = useState([]);
    const [tipoFalla,setTipoFalla] = useState(undefined);

    const [ altura, setAltura ] = useState(0);
    const [ diametro, setDiametro ] = useState(0);
    const [ carga, setCarga ] = useState(0);

    const [area,setArea] = useState(0);
    const [resistencia,setResistencia] = useState(0);
    const [porcentaje,setPorcentaje] = useState(0);
    const history = useHistory();


    const tiposFalla = [
        { value:'Falla 1',label:'Falla 1',name:'tipo_falla' },
        { value:'Falla 2',label:'Falla 2',name:'tipo_falla' },
        { value:'Falla 3',label:'Falla 3',name:'tipo_falla' },
        { value:'Falla 4',label:'Falla 4',name:'tipo_falla' },
        { value:'Falla 5',label:'Falla 5',name:'tipo_falla' },
        { value:'Falla 6',label:'Falla 6',name:'tipo_falla' },
    ];

    useEffect(() => {

        if(props.record.carga > 0) {
            setDataForm(props.record);
            setTipoFalla(props.record.tipo_falla);
            setAltura(props.record.altura_1);
            setDiametro(props.record.diametro_lado_1);
            setCarga(props.record.carga);

            calculaResistencia(props.record.diametro_lado_1,props.record.carga);

            props.setButtonGuardarDisabled(false);

        }
    },[]);
    
    useEffect(() => {

        if( diametro && carga) {
            calculaResistencia(diametro,carga);
        }

    },[diametro,carga]);

    const calculaResistencia = (diametro,carga) => {
        let area = 3.1416*(Math.pow(diametro,2)/4);

        let resistencia = carga/area;

        setArea(area);
        setResistencia(Math.round(resistencia));
        setPorcentaje(Math.round((resistencia/props.resistenciaProyecto)*100));
    };


    const onSubmit = async (data) => {

        data.carga = carga;
        data.porcentaje_resistencia = porcentaje;

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(data,'ensayes',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado el ensaye",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar el ensaye",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }


            //Redirect al formulario de edición
            history.push(`/capturista/ensayes`);
        }
        
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Altura (cm)</Label>
                        <input className="form-control" 
                               type="number"
                               name="altura_1"
                               onChange={(event) => {
                                   setAltura(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.altura_1 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.altura_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Diámetro (cm)</Label>
                        <input className="form-control"
                               type="number"
                               name="diametro_lado_1"
                               onChange={(event) => {
                                   setDiametro(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.diametro_lado_1 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.diametro_lado_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Carga (Kgf)</Label>
                        <input className="form-control"
                               type="number"
                               name="carga"
                               onChange={(event) => {
                                   setCarga(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.carga : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.carga && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>* Tipo de falla</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposFalla }
                                placeholder="Selecciona ..."
                                value={tiposFalla.find(op => {
                                    return op.value === tipoFalla
                                })}
                                onChange={(event) => {setTipoFalla(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ tipoFalla }
                            name="tipo_falla"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_falla && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Área: { area } (cm²) <br/>
                        Resistencia: { resistencia } (kgf/cm²) <br/>
                        Porcentaje: { porcentaje }%<br/>
                    </p>
                </Col>
            </Row>
        </Form>
    )
};

export default withRouter(Cilindro);