import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import {useHistory, withRouter} from "react-router-dom";

const Seccion3 = props => {
    const { register,handleSubmit,errors } = useForm();
    const [idTipoGrado,setidTipoGrado] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const tiposGrado = [
        { value:'42', label:'42', name:'grado' },
        { value:'50', label:'50', name:'grado' },
        { value:'60', label:'60', name:'grado' }
    ];
    const history = useHistory();

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setidTipoGrado(data.grado);
            setDataForm(data);
        }
    },[]);

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Grado</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposGrado }
                                placeholder="Selecciona ..."
                                value={tiposGrado.find(op => {
                                    return op.value === idTipoGrado
                                })}
                                onChange={(event) => {setidTipoGrado(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoGrado }
                            name="grado"
                            ref={ register({ required:true }) }
                        />
                        { errors.grado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Fajo</Label>
                        <input className="form-control"
                               type="text"
                               name="fajo"
                               defaultValue={ dataForm?.fajo ? dataForm.fajo : undefined }
                               ref={ register({ required:true }) }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Masa</Label>
                        <input className="form-control"
                               type="text"
                               name="masa"
                               defaultValue={ dataForm ? dataForm.masa : undefined }
                               ref={ register({ required:true }) }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Diámetro</Label>
                        <input className="form-control"
                               type="text"
                               name="diametro"
                               defaultValue={ dataForm?.diametro ? dataForm.diametro : undefined }
                               ref={ register({ required:true }) }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Ancho costilla</Label>
                        <input className="form-control"
                               type="text"
                               name="ancho_costilla"
                               defaultValue={ dataForm ? dataForm.ancho_costilla : undefined }
                               ref={ register({ required:true }) }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Carga máxima</Label>
                        <input className="form-control"
                               type="text"
                               name="carga_maxima"
                               defaultValue={ dataForm?.carga_maxima ? dataForm.carga_maxima : undefined }
                               ref={ register({ required:true }) }/>
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Alargamiento</Label>
                        <input className="form-control"
                               type="text"
                               name="alargamiento"
                               defaultValue={  dataForm?.alargamiento ?  dataForm?.alargamiento : undefined }
                               ref={ register({ required:true }) }/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
};

export default withRouter(Seccion3);