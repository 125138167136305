import React, {useEffect, useState} from "react";
import {  Col, FormGroup, Label, Row, Form } from "reactstrap";
import Select from "react-select";
import { customStylesSelect } from "../../../../../../constants/customStylesSelect";
import { useForm } from "react-hook-form";

const Seccion1 = props => { 
    const {  register, handleSubmit, errors  } = useForm();
    const [idCompactacionDe,setIdCompactacionDe] = useState(undefined);
    const [idTipoCompactacion,setIdTipoCompactacion] = useState(undefined);

    const [idMetodoCompactacion,setIdMetodoCompactacion] = useState(undefined);
    const [idTipoSecado,setIdTipoSecado] = useState(undefined);
    const [dataForm,setDataForm] = useState(null);

    const compactacionDe = [
        { value:'Terracerias',label:'Terracerias',name:'compactacion_de' },
        { value:'Vialidades',label:'Vialidades',name:'compactacion_de' },
        { value:'Cepas e instalaciones',label:'Cepas e instalaciones',name:'compactacion_de' },
        { value:'Plataformas',label:'Plataformas',name:'compactacion_de' },
        { value:'Plataforma vivienda',label:'Plataforma vivienda',name:'compactacion_de' },
        { value:'Desplante',label:'Desplante',name:'compactacion_de' },
    ];
    
    const metodosCompactacion = [
        { value:'Probeta',label:'Probeta',name:'metodo_compactacion' },
        { value:'Cono',label:'Cono',name:'metodo_compactacion' },
        { value:'Trompa',label:'Trompa',name:'metodo_compactacion' },
        { value:'Densimetro',label:'Densimetro',name:'metodo_compactacion' },
    ];

    const tiposSecado = [
        { value:'Horno',label:'Horno',name:'secado' },
        { value:'Estufa',label:'Estufa',name:'secado' },
    ];
    
    const tiposCompactacion = [
        { value:'Rodillo',label:'Rodillo',name:'tipo_compactacion' },
        { value:'Bailarina',label:'Bailarina',name:'tipo_compactacion' },
        { value:'Plancha',label:'Plancha',name:'tipo_compactacion' },
        { value:'Pata de cabra',label:'Pata de cabra',name:'tipo_compactacion' },
        { value:'Pison',label:'Pison',name:'tipo_compactacion' },
    ];

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
            setIdCompactacionDe(props.mainObjectData[1].datos_muestra.compactacion_de);
            setIdMetodoCompactacion(props.mainObjectData[1].datos_muestra.metodo_compactacion);
            setIdTipoCompactacion(props.mainObjectData[1].datos_muestra.tipo_compactacion);
            setIdTipoSecado(props.mainObjectData[1].datos_muestra.secado);
        }
    },[]);

    const onSubmit = (data) => {
        let objData = props.mainObjectData;
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);
        props.setSeccion(2);
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Compactación de</Label>
                        <Select styles={ customStylesSelect }
                                options={ compactacionDe }
                                placeholder="Selecciona ..."
                                value={compactacionDe.find(op => {
                                    return op.value === idCompactacionDe
                                })}
                                onChange={(event) => {setIdCompactacionDe(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idCompactacionDe }
                            name="compactacion_de"
                            ref={ register({ required:true }) }
                        />
                        { errors.compactacion_de && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Báscula</Label>
                        <input className="form-control"
                               type="text"
                               name="bascula"
                               defaultValue={ dataForm ? dataForm.bascula : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.bascula && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Método de compactación</Label>
                        <Select styles={ customStylesSelect }
                                options={ metodosCompactacion }
                                placeholder="Selecciona ..."
                                value={metodosCompactacion.find(op => {
                                    return op.value === idMetodoCompactacion
                                })}
                                onChange={(event) => {setIdMetodoCompactacion(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idMetodoCompactacion }
                            name="metodo_compactacion"
                            ref={ register({ required:true }) }
                        />
                        { errors.compactacion_de && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de secado</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposSecado }
                                placeholder="Selecciona ..."
                                value={tiposSecado.find(op => {
                                    return op.value === idTipoSecado
                                })}
                                onChange={(event) => {setIdTipoSecado(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoSecado }
                            name="secado"
                            ref={ register({ required:true }) }
                        />
                        { errors.secado && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Peso volumen arena</Label>
                        <input className="form-control"
                               type="text"
                               name="peso_volumen_arena"
                               defaultValue={ dataForm ? dataForm.peso_volumen_arena : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.peso_volumen_arena && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Grado de compactación proyecto</Label>
                        <input className="form-control"
                               type="text"
                               name="grado_compactacion"
                               defaultValue={ dataForm ? dataForm.grado_compactacion : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.grado_compactacion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Tipo de compactación</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposCompactacion }
                                placeholder="Selecciona ..."
                                value={tiposCompactacion.find(op => {
                                    return op.value === idTipoCompactacion
                                })}
                                onChange={(event) => {setIdTipoCompactacion(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ idTipoCompactacion }
                            name="tipo_compactacion"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_compactacion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default Seccion1;