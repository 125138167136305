import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {Viewer} from "@react-pdf-viewer/core";
import {url_base} from "../../../../constants/api_url";
import CookieService from "../../../../services/CookieService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPrint} from "@fortawesome/free-solid-svg-icons";
import StyledButton from "../../button";
import {fetchRecord} from "../../../../actions/fetchRecord";
import {useUsuario} from "../../../../context/usuario-context";
import {storeRecord} from "../../../../actions/storeRecord";

const GenerarReporte = props => {
    const { setBreadcrumbItems,usuario } = useUsuario();

    const authToken = CookieService.get('access_token');
    const idMuestra  = props.match.params.idMuestra;
    const firmas  = props.match.params.firmas;
    const [tipoMuestra, setTipoMuestra] = useState(null);
    const [idReporte,setIdReporte] = useState(null);


    useEffect(() => {

        setBreadcrumbItems([
            {
                'link':'/capturista',
                'item':'Tablero de control'
            },
            {
                'link':'/capturista/muestras',
                'item':`Muestras ${tipoMuestra}`
            },
            {
                'link':`/capturista/muestras/reportes/${idMuestra}`,
                'item':`Reportes muestra ${idMuestra}`
            },
            {
                'item':'Generar Reporte'
            }
        ]);

        if(idMuestra) {
            getRecord();
            crearReporte();
        }
    },[]);

    async function getRecord() {
        try {
            const result = await fetchRecord(idMuestra,'muestras');
            if(result) {
                setTipoMuestra(result.done.muestra.tipo_muestra);
            }
        }catch (e) {
            console.log(e);
        }
    }

    const crearReporte = async () => {
        const payload = {
            idMuestra:idMuestra,
            idCapturista:usuario.user.id,
            conFirmas: firmas
        }

        try {
            const response = await storeRecord(payload,'reportes');
            if(response) {
                setIdReporte(response.done.id); //Se define el id del reporte recién creado
            }
        }catch (e){
            console.log(e);
        }
    };

    return(
        <div>
            <Row className="mt-4">
                <Col>

                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '750px',
                        }}
                    >
                        {idReporte && <Viewer credentials={false} authorization={`Bearer ${authToken}`} httpHeaders={{
                            "Content-Type": "blob",
                            "Accept": "application/json, text/plain, */*",
                        }} fileUrl={`${url_base}reportes/${idReporte}/${firmas}`} />}

                    </div>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-between">
                <Col sm={ 4 } className="text-right">
                    <Button className="secondary">
                        <FontAwesomeIcon className="mr-3" icon={faPrint}/>
                        Imprimir
                    </Button>
                </Col>
                <Col sm={ 4 } className="text-left">
                    <a href={`${url_base}reportes/${idReporte}/${firmas}`} download={`reporte-${tipoMuestra}-${idMuestra}.pdf`}>
                        <StyledButton className="gray">
                            <FontAwesomeIcon className="mr-3" icon={faDownload}/>
                            Descargar
                        </StyledButton>
                    </a>

                </Col>
            </Row>
        </div>
    )
}

export default GenerarReporte;