import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {customStylesSelect} from "../../../../../../constants/customStylesSelect";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import { withRouter, useHistory } from 'react-router-dom';

const Cubo = ( props ) => {
    const {  register, handleSubmit, errors  } = useForm();
    const [dataForm,setDataForm] = useState(null);
    const [tipoFalla,setTipoFalla] = useState(undefined);

    const [ lado1, setLado1 ] = useState(0);
    const [ lado2, setLado2 ] = useState(0);
    const [ carga, setCarga ] = useState(0);

    const [area,setArea] = useState(0);
    const [resistencia,setResistencia] = useState(0);
    const [porcentaje,setPorcentaje] = useState(0);
    const history = useHistory();


    const tiposFalla = [
        { value:'Falla 1',label:'Falla 1',name:'tipo_falla' },
        { value:'Falla 2',label:'Falla 2',name:'tipo_falla' },
        { value:'Falla 3',label:'Falla 3',name:'tipo_falla' },
    ];

    useEffect(() => {

        if(props.record.carga > 0) {
            setDataForm(props.record);
            setTipoFalla(props.record.tipo_falla);
            setLado1(props.record.altura_1);
            setLado2(props.record.altura_2);
            setCarga(props.record.carga);

            calculaResistencia(props.record.altura_1, props.record.altura_2, props.record.carga);

            props.setButtonGuardarDisabled(false);

        }
    },[]);

    useEffect(() => {

        if( lado1 && lado2 && carga) {
            calculaResistencia(lado1, lado2, carga);
        }

    },[lado1,lado2,carga]);

    const calculaResistencia = (lado1, lado2, carga) => {
        let area = lado1*lado2;

        let resistencia = carga/area;

        setArea(area);
        setResistencia(Math.round(resistencia));
        setPorcentaje(Math.round((resistencia/props.resistenciaProyecto)*100));
    };

    const onSubmit = async (data) => {

        data.carga = carga;
        data.porcentaje_resistencia = porcentaje;

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(data,'ensayes',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado el ensaye",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar el ensaye",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }


            //Redirect al formulario de edición
            history.push(`/capturista/ensayes`);
        }

    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Lado 1</Label>
                        <input className="form-control"
                               type="number"
                               name="altura_1"
                               onChange={(event) => {
                                   setLado1(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.altura_1 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.altura_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Lado 2</Label>
                        <input className="form-control"
                               type="number"
                               name="altura_2"
                               onChange={(event) => {
                                   setLado2(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.altura_2 : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.altura_2 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Carga (Kgf)</Label>
                        <input className="form-control"
                               type="number"
                               name="carga"
                               onChange={(event) => {
                                   setCarga(event.target.value);
                               }}
                               defaultValue={ dataForm ? dataForm.carga : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.revenimiento_1 && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>* Tipo de falla</Label>
                        <Select styles={ customStylesSelect }
                                options={ tiposFalla }
                                placeholder="Selecciona ..."
                                value={tiposFalla.find(op => {
                                    return op.value === tipoFalla
                                })}
                                onChange={(event) => {setTipoFalla(event.value)}}
                        />
                        <input
                            tabIndex={ -1 }
                            autoComplete="off"
                            style={ {  opacity: 0, height: 0, display: 'none' } }
                            value={ tipoFalla }
                            name="tipo_falla"
                            ref={ register({ required:true }) }
                        />
                        { errors.tipo_falla && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Área: { area } (cm²) <br/>
                        Resistencia: { resistencia } (kgf/cm²) <br/>
                        Porcentaje: { porcentaje }%<br/>
                    </p>
                </Col>
            </Row>
        </Form>
    )
};

export default withRouter(Cubo);