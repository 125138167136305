import React, {useEffect, useState} from "react";
import {Row, Col, Button} from "reactstrap";
import MUIDataTable from "mui-datatables";
import {muiTableOptions} from "../../../constants/muiTableOptions";
import {fetchRecords} from "../../../actions/fetchRecords";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, withRouter} from "react-router-dom";
import ModalEliminarRegistro from "../../common/modals/modalEliminarRegistro";
import {deleteRecord} from "../../../actions/deleteRecord";

const Clientes = ( ) => {
    const [records,setRecords] = useState([]);
    const [modalEliminar,setModalEliminar] = useState(false);
    const [tituloModal,setTituloModal] = useState(false);
    const [selectedRecordId,setSelectedRecordId] = useState(null);
    const history = useHistory();

    const columns = [{
        name: "nombre_completo",
        label: "Nombre",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "email",
        label: "Correo",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "telefono",
        label: "Teléfono",
        options: {
            filter: false,
            sort: false,
        }
    },{
        name: "idCliente",
        label: "Acciones",
        options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <div>
                        <Button type="Button" title="Editar" onClick={() => {

                            //Redirect al formulario de edición
                            history.push(`/admin/clientes/editar/${tableMeta.rowData[3]}`);

                        }} className="mr-2 btnAction"><FontAwesomeIcon icon={faEdit}/>
                        </Button>

                        <Button type="Button" title="Eliminar" onClick={() => {
                            setSelectedRecordId(tableMeta.rowData[3]);
                            setModalEliminar(!modalEliminar);
                            setTituloModal(tableMeta.rowData[0]);
                        }} className="mr-2 btnAction"><FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                );
            }
        }
    }];

    useEffect(() => {
        getRecords();
    },[]);

    const getRecords = async () => {
        try {
            const result = await fetchRecords('clientes');
            if(result) {
                setRecords(result.done);
            }
        }catch (e) {
            console.log(e);
        }
    };

    const eliminarRegistro = async () => {
        try {

            setModalEliminar(false);

            await deleteRecord(selectedRecordId,'clientes');

            await getRecords();

        }catch (e) {
            console.log(e);
        }
    };

    return(
        <div>
            { modalEliminar && <ModalEliminarRegistro
                toggleDeleteModal={() => setModalEliminar(!modalEliminar)}
                deleteModal={modalEliminar}
                titulo={tituloModal}
                deleteRegister={() => eliminarRegistro()}
            /> }
            <Row className="justify-content-end">
                <Col sm={3} className="text-right">
                    <Link to={'/admin/clientes/agregar'}>
                        <Button className="primary">
                            Agregar cliente
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <MUIDataTable
                        title={"Clientes"}
                        data={records}
                        columns={columns}
                        options={muiTableOptions}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default withRouter(Clientes);