import React from "react";
import TablaEnsayesConcreto from "../common/tablaEnsayesConcreto";

const url = 'ensayes-tipo/Calidad';
const tituloTabla = 'Ensayes pendientes de Calidad';
const tipoMuestra = 'Calidad';

const EnsayesCompresion = () => (
    <TablaEnsayesConcreto
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default EnsayesCompresion;