import React from "react";
import Ensayes from "../index";

const url = 'ensayes-tipo/Morteros';
const tituloTabla = 'Ensayes pendientes de Morteros';
const tipoMuestra = 'Morteros';

const EnsayesMortero = () => (
    <Ensayes
        url={url}
        tituloTabla={tituloTabla}
        tipoMuestra={tipoMuestra}
    />
);

export default EnsayesMortero;