import React, {useEffect} from "react";
import { Container } from "reactstrap";
import Header from "../common/header";
import './styles.scss';
import {withRouter} from 'react-router-dom';
import {useUsuario} from "../../context/usuario-context";

const Admin = props => {
    const { filterBar } = useUsuario();

    useEffect(() => {
        console.log('changed filter bar');
    },[]);

    return(
        <div>
            <Header/>
            <div className={'dashboard-content animate fadeInUp one p-1 p-lg-5 ' + (filterBar ? 'filterBar' : '')}>
                <Container>
                    {props.children}
                </Container>
            </div>
        </div>
    )
};

export const AdminContainerWithRouter = withRouter(Admin);
