import React, {useEffect, useState} from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import './styles.scss';
import {Link} from "react-router-dom";
import {fetchRecords} from "../../../actions/fetchRecords";
import {useUsuario} from "../../../context/usuario-context";

const Bienvenida = () => {

    const { setFilterBar } = useUsuario();
    const [ cantidades, setCantidades ] = useState({});

    useEffect(() => {
        setFilterBar(false);
    },[]);

    useEffect(() => {
        async function getCantidades() {
            try {
                const result = await fetchRecords('admin/countRegistros');

                if(result) {
                    setCantidades({
                        ...result.done
                    })
                }

                console.log(cantidades)
            }catch (e) {
                console.log(e);
            }
        }

        getCantidades();
    },[]);

    return(
        <div>
            <Row className="mt-4 text-center">
                <Col>
                    <h4 className="d-lg-none mt-5">Bienvenido a SM Ingeniería</h4>
                    <h3 className="d-none d-lg-inline mt-5">Bienvenido a SM Ingeniería</h3>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="mt-2 mt-lg-4" sm="6">
                    <Card className="shadow">
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Capturistas</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#1D6AAE'}}>{cantidades.capturistas}</h1>
                            <Link to={'admin/capturistas'}>
                                <Button className="mt-2 primary">
                                    Administrar capturistas
                                </Button>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mt-2 mt-lg-4" sm="6">
                    <Card className="shadow" >
                        <CardBody className="text-center" style={{height:190}}>
                            <CardTitle>
                                <h3>Clientes</h3>
                            </CardTitle>
                            <h1 className="mt-2" style={{color:'#1D6AAE'}}>{cantidades.clientes}</h1>
                            <Link to={'admin/clientes'}>
                                <Button className="mt-2 primary">
                                    Administrar clientes
                                </Button>
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default Bienvenida;
