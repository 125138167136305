import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import {useHistory, withRouter } from "react-router-dom";
const Seccion2 = props => { 
    const { register,handleSubmit,errors } = useForm();
    const [dataForm,setDataForm] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
        }
    },[]);
    
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras/Blocks');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras/Blocks');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
     };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Lote</Label>
                        <input className="form-control"
                               type="text"
                               name="lote"
                               defaultValue={ dataForm ? dataForm.lote : undefined }
                                                ref={ register }/>      
                        { errors.lote && <small>Ingresa un valor</small> }

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Nivel</Label>
                        <input className="form-control"
                               type="text"
                               name="nivel"
                               defaultValue={ dataForm?.nivel ? dataForm.nivel : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Etapa</Label>
                        <input className="form-control"
                               type="text"
                               name="etapa"
                               defaultValue={ dataForm ? dataForm.etapa : undefined }
                               ref={ register }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Elemento</Label>
                        <input className="form-control"
                               type="text"
                               name="elemento"
                               defaultValue={ dataForm ? dataForm.elemento : undefined }
                               ref={ register }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Manzana</Label>
                        <input className="form-control"
                               type="text"
                               name="manzana"
                               defaultValue={  dataForm?.manzana ?  dataForm?.manzana : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Cadenamiento</Label>
                        <input className="form-control"
                               type="text"
                               name="cadenamiento"
                               defaultValue={  dataForm?.cadenamiento ?  dataForm?.cadenamiento : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Eje</Label>
                        <input className="form-control"
                               type="text"
                               name="eje"
                               defaultValue={  dataForm?.eje ?  dataForm?.eje : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Paquete</Label>
                        <input className="form-control"
                               type="text"
                               name="paquete"
                               defaultValue={  dataForm?.paquete ?  dataForm?.paquete : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Fracción</Label>
                        <input className="form-control"
                               type="text"
                               name="fraccion"
                               defaultValue={  dataForm?.fraccion ?  dataForm?.fraccion : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>Unidad</Label>
                        <input className="form-control"
                               type="text"
                               name="unidad"
                               defaultValue={  dataForm?.unidad ?  dataForm?.unidad : undefined }
                                                ref={ register }/>      

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 8 }>
                    <FormGroup>
                        <Label>Notas</Label>
                        <textarea className="form-control"
                                  type="textarea"
                                  name="notas"
                                  defaultValue={ dataForm?.notas ?  dataForm?.notas : undefined }
                                                   ref={ register }/>

                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
 };

export default withRouter(Seccion2);