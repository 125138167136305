import React from "react";
import Cilindro from "./cilindro";
import Cubo from "./cubo";

const Compresion = ( props ) => {

    return(
        <div>
            { props.formaEnsaye === 'Cilindro' ? <Cilindro setSeccion={ props.setSeccion }
                                                           record={ props.record }
                                                           idRegistro={ props.idRegistro }
                                                           resistenciaProyecto={ props.resistenciaProyecto }
                                                           setButtonGuardarDisabled={ props.setButtonGuardarDisabled }
            /> : '' }
            { props.formaEnsaye === 'Cubo' ? <Cubo setSeccion={ props.setSeccion }
                                                   record={ props.record }
                                                   idRegistro={ props.idRegistro }
                                                   resistenciaProyecto={ props.resistenciaProyecto }
                                                   setButtonGuardarDisabled={ props.setButtonGuardarDisabled }
            /> : '' }
        </div>
    )
};

export default Compresion;