import React, {useEffect, useState} from "react";
import { Col, FormGroup, Label, Row, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import {updateRecord} from "../../../../../../actions/updateRecord";
import {store} from "react-notifications-component";
import {storeRecord} from "../../../../../../actions/storeRecord";
import {useHistory, withRouter} from "react-router-dom";

const Seccion3 = props => {
    const { register,handleSubmit,errors } = useForm();
    const [dataForm,setDataForm] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if(Object.keys(props.mainObjectData[1].datos_muestra).length !== 0) {
            const data = props.mainObjectData[1].datos_muestra;
            setDataForm(data);
        }
    },[]);

    /**
     * Función onSubmit
     * @param event
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {

        let objData = props.mainObjectData; //Objeto principal con todos los datos de formularios anteriores
        Object.assign(objData[1].datos_muestra,data);
        props.setMainObjectData(objData);

        //Si contiene el idRegistro se entra al modo update, de lo contrario se crea un nuevo registro
        if(props.idRegistro) {

            try {

                const response = await updateRecord(objData,'muestras',props.idRegistro);

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha actualizado la muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }catch (e) {
                console.log(e);
                store.addNotification({
                    title: "Ocurrió un error al actualizar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

            history.push('/capturista/muestras');

        }else{
            try {

                const response = await storeRecord(objData,'muestras');

                if(response) {
                    store.addNotification({
                        title: "Correcto",
                        message: "Se ha creado una nueva muestra",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    history.push('/capturista/muestras');
                }

            }catch (e) {
                store.addNotification({
                    title: "Ocurrió un error al agregar la muestra",
                    message: "Revisa tu conexión a internet",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    };

    return(
        <Form id="form-child-muestra" onSubmit={ handleSubmit(onSubmit) } className="animate fadeIn one">
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Masa volumétrica seca suelta</Label>
                        <input className="form-control"
                               type="text"
                               name="masa_volumetrica_seca_suelta"
                               defaultValue={ dataForm?.masa_volumetrica_seca_suelta ? dataForm.masa_volumetrica_seca_suelta : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.masa_volumetrica_seca_suelta && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* V.R.S (Estándar)</Label>
                        <input className="form-control"
                               type="text"
                               name="vrs_estandar"
                               defaultValue={ dataForm ? dataForm.vrs_estandar : undefined }
                               ref={ register({ required:true }) }/>
                       { errors.vrs_estandar && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Límite líquido</Label>
                        <input className="form-control"
                               type="text"
                               name="limite_liquido"
                               defaultValue={ dataForm?.limite_liquido ? dataForm.limite_liquido : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.limite_liquido && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Desgaste de los ángeles</Label>
                        <input className="form-control"
                               type="text"
                               name="desgaste_angeles"
                               defaultValue={ dataForm ? dataForm.desgaste_angeles : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.desgaste_angeles && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Humedad del lugar</Label>
                        <input className="form-control"
                               type="text"
                               name="humedad_lugar"
                               defaultValue={ dataForm ? dataForm.humedad_lugar : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.humedad_lugar && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Expansión</Label>
                        <input className="form-control"
                               type="text"
                               name="expansion"
                               defaultValue={  dataForm?.expansion ?  dataForm?.expansion : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.expansion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Masa volumétrica seca compacta</Label>
                        <input className="form-control"
                               type="text"
                               name="masa_volumetrica_seca_compacta"
                               defaultValue={ dataForm?.masa_volumetrica_seca_compacta ? dataForm.masa_volumetrica_seca_compacta : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.masa_volumetrica_seca_compacta && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Límite plástico</Label>
                        <input className="form-control"
                               type="text"
                               name="limite_plastico"
                               defaultValue={  dataForm?.limite_plastico ?  dataForm?.limite_plastico : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.limite_plastico && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Contracción lineal</Label>
                        <input className="form-control"
                               type="text"
                               name="contraccion_lineal"
                               defaultValue={  dataForm?.contraccion_lineal ?  dataForm?.contraccion_lineal : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.contraccion_lineal && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Grado compactación</Label>
                        <input className="form-control"
                               type="text"
                               name="grado_compactacion"
                               defaultValue={ dataForm?.grado_compactacion ? dataForm.grado_compactacion : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.grado_compactacion && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Porcentaje humedad</Label>
                        <input className="form-control"
                               type="text"
                               name="porcentaje_humedad"
                               defaultValue={  dataForm?.porcentaje_humedad ?  dataForm?.porcentaje_humedad : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.porcentaje_humedad && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Equivalente arena</Label>
                        <input className="form-control"
                               type="text"
                               name="equivalente_arena"
                               defaultValue={  dataForm?.equivalente_arena ?  dataForm?.equivalente_arena : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.equivalente_arena && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Indice plástico</Label>
                        <input className="form-control"
                               type="text"
                               name="indice_plastico"
                               defaultValue={ dataForm?.indice_plastico ? dataForm.indice_plastico : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.indice_plastico && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Masa volumétrica del lugar</Label>
                        <input className="form-control"
                               type="text"
                               name="masa_volumetrica_lugar"
                               defaultValue={  dataForm?.masa_volumetrica_lugar ?  dataForm?.masa_volumetrica_lugar : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.masa_volumetrica_lugar && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
                <Col sm={ 4 }>
                    <FormGroup>
                        <Label>* Procedencia</Label>
                        <input className="form-control"
                               type="text"
                               name="procedencia"
                               defaultValue={  dataForm?.procedencia ?  dataForm?.procedencia : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.procedencia && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>* Muestreo</Label>
                        <input className="form-control"
                               type="text"
                               name="muestreo"
                               defaultValue={  dataForm?.muestreo ?  dataForm?.muestreo : undefined }
                               ref={ register({ required:true }) }/>
                        { errors.muestreo && <small>Ingresa un valor</small> }
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
};

export default withRouter(Seccion3);